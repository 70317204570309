import * as yep from "yup";

const msg = {
  requiredFirstName: "Le prénom est obligatoire",
  requiredLastName: "Le nom est obligatoire",
  invalidEmail: "S'il vous plaît, mettez une adresse email valide",
  requiredEmail: "Adresse e-mail est nécessaire",
  requiredPhone: "Numéro de téléphone est requis",
};

export const completerFormSchema = yep.object().shape({
  phone: yep.string().required(msg.requiredPhone),
  email: yep.string().email(msg.invalidEmail).required(msg.requiredEmail),
  last_name: yep.string().required(msg.requiredLastName),
  first_name: yep.string().required(msg.requiredFirstName),
});

export const validateCompleterForm = (values) => {
  let errors = {};

  if (!values.first_name) {
    errors.first_name = msg.requiredFirstName;
  } else if (values.first_name.length < 2) {
    errors.first_name = "Le prénom doit comporter au moins 2 caractères";
  }

  if (Object.keys(errors).length) {
    // Return immediately if there are any errors so we only show the first one
    return errors;
  }

  if (!values.last_name) {
    errors.last_name = msg.requiredLastName;
  } else if (values.last_name.length < 2) {
    errors.last_name = "Le nom doit comporter au moins 2 caractères";
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  if (!values.email && (!values.phone.split('-')[1] || !isValidPhone(values.phone.split('-')[1]))) {
    errors.email = msg.requiredEmail;
  } else if (values.email && !isValidEmail(values.email)) {
    errors.email = msg.invalidEmail;
  } else if (!values.phone.split('-')[1] && !values.email) {
    errors.phone = msg.requiredPhone;
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  return {};
};

const isValidEmail = (email) => {
  // maket it simple just for normal text
  return true;
};

const isValidPhone = (phone) => {
  // just return
  return true;
};
