import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingStep } from "../../redux/appSlices/collaborator/collaboratorSlice";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import { translation } from "../../translation";
import { toast } from "react-toastify";

const Calendly = ({ edit, setOpenEditPopup }) => {

  const dispatch = useDispatch()
  // const calendlyTranslation = useSelector(
  //   (state) => state.translation.Calendly
  // );
  const calendlyTranslation = translation.onboarding.calendly

  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();

  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

  // ############### DEV AREA
  // ######################
  // ########################################################

  const [safe, setSafe] = useState(true)
  const [loading, setLoading] = useState()

  const handleSubmit = () => {
    patchCollaborator({
      id: collaborator._id,
      content: { appointment: calendly, ...(edit ? {} : { onboarding_step: 7 }) }
    })
      .unwrap()
      .then()
      .catch(() => toast.error(edit ? translation?.updateErrorMessage?.[lang] : translation?.saveErrorMessage?.[lang]))
  };

  // ########################################################
  // ######################
  // ############### END DEV AREA

  const [calendly, setCalendly] = useState(collaborator.appointment);

  return (
    <section className="pt-[7vh] mx-5">
      <div>
        <div className={`text-2xl ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>{calendlyTranslation.title[lang]}</div>
        <div className={`my-5 text-lg ${lang === 'ar' ? '!font-readexRegular' : ''}`}>{calendlyTranslation.subtitle[lang]}</div>
        <div>
          <div className="flex justify-between items-center w-[122px] h-[44px] mt-5 py-2 px-[9px] bg-[#fff3e7] border border-[#ccb7b7] rounded-md">
            Calendly
            {restriction.restrict_appointment && (<img src="../../assets/img/icons/lock.svg" className="w-[15px] h-[15px]" />)}
          </div>

          {
            restriction.restrict_appointment ? (
              <div className="my-4 p-2 text-[18px] tracking-[-.73px] leading-[25px]">
                {restriction.appointment}
              </div>
            ) : (
              <input
                className="block my-4 p-2 bg-popcard w-full border-b border-inputBottom outline-none"
                onChange={(e) => setCalendly(e.target.value)}
                placeholder="https://calendly.com/user-name"
                defaultValue={calendly}
              />
            )
          }

        </div>

        <div className="mt-8 mb-5">
          <span className={` ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'} font-bold  text-labels text-lg`}>
            {calendlyTranslation.tutoriel[lang]}
          </span>

          <a href="http://calendly.com" className="text-sky-700"> Calendly.com</a>
        </div>

        <div className="video-preview">
          <iframe
            width="100%"
            height={220}
            src="https://www.youtube.com/embed/MB6Wp72s4Ow"
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
          />
        </div>
      </div>

      <div className="button-container">
        <button
          className={`${safe ? "btn-fields btn-active" : "btn-fields"} ${lang === 'ar' ? '!font-readexRegular' : ''}`}
          id="btn-next"
          onClick={
            edit
              ? (e) => {
                handleSubmit(e);
                setOpenEditPopup(false);
              }
              : handleSubmit
          }
          disabled={!safe}
        >
          {edit ? (
            <img
              src="/assets/img/loaders/loading.gif"
              style={{
                display: loading ? undefined : "none",
                padding: "2px",
              }}
              width="23px"
              alt=""
              className="ml-[45%]"
            />
          ) : null}
          {loading
            ? null
            : edit
              ? calendlyTranslation?.buttonSave[lang]
              : calendlyTranslation?.buttonNext[lang]}
        </button>
      </div>
    </section>
  );
};

export default Calendly;
