import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Phone from "../Phone";
import { v4 as uuidv4 } from "uuid";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import { setOnboardingStep } from "../../redux/appSlices/collaborator/collaboratorSlice";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import { translation } from "../../translation";
import { toast } from "react-toastify";
import { languages } from "../../constants";

const Details = ({ edit, setOpenEditPopup }) => {
  // const detailsTranslation = useSelector(
  //   (state) => state.translation.onboardingDetails
  // );

  const detailsTranslation = translation.onboarding.onboardingDetails
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();
  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

  // send this data to the api
  const [colaboratorDetails, setColaboratorDetails] = useState({
    first_name: collaborator?.first_name ?? "",
    last_name: collaborator?.last_name ?? "",
    // company: { [lang]: collaborator?.company?.[lang] } || "",
    company: collaborator?.company || "",
    // title: {[lang]: collaborator?.title?.[lang] || "",},
    title: collaborator?.title || "",
  });
  const [phoneList, setPhoneList] = useState(() => {
    if (collaborator.phone.length === 0) {
      return [{ prefix: "212", number: "", _id: uuidv4() }];
    } else {
      return collaborator.phone;
    }
  });

  const [safe, setSafe] = useState(true);
  const [loading, setSLoading] = useState(false);

  useEffect(() => {
    if (
      colaboratorDetails.first_name.length > 0 &&
      colaboratorDetails.last_name.length > 0
    ) {
      setSafe(true);
    } else {
      setSafe(false);
    }
  }, [colaboratorDetails]);

  const HandleChange = (e) => {
    if (e.target.name == "title") {
      setColaboratorDetails({
        ...colaboratorDetails,
        [e.target.name]: languages.reduce((acc, l) => {
          acc[l] = l === lang ? e.target.value : '';
          return acc;
        }, {}),
      });
    } else if (e.target.name == "company") {
      setColaboratorDetails({
        ...colaboratorDetails,
        [e.target.name]: {
          fr: e.target.value,
          en: e.target.value,
          ar: e.target.value,
        },
      });
    } else {
      setColaboratorDetails({
        ...colaboratorDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const content = {
      ...colaboratorDetails,
      ...(edit ? {} : { onboarding_step: 2 }),
      phone: phoneList,
    };

    patchCollaborator({
      id: collaborator._id,
      content
    })
      .unwrap()
      .then()
      .catch(() => toast.error(edit ? translation?.updateErrorMessage?.[lang] : translation?.saveErrorMessage?.[lang]))
  };

  // ############### Phone Add
  // ######################
  // ########################################################

  const onDelete = (index) => {
    const newPhone = phoneList.filter((item, i) => item._id !== index);
    setPhoneList(newPhone);
  };
  const handleAdd = () => {
    setPhoneList([...phoneList, { prefix: "212", number: "", _id: uuidv4() }]);
  };

  const handlePhoneChange = (e, index) => {
    const phones = [...phoneList];
    phones[index] = {
      ...phones[index],
      [e.target.name]: e.target.value,
    };
    setPhoneList(phones);
  };

  // ########################################################
  // ######################
  // ############### END Phone Add

  return (
    <section className={`pt-[7vh] mx-5 pb-[120px] ${lang === 'ar' ? 'font-readexRegular' : 'font-SteradianRegular'}`}>
      <div>
        <div className={`${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'} text-2xl`}>
          {detailsTranslation.title[lang]}
        </div>
        <div className="mt-5 text-lg">{detailsTranslation.subtitle[lang]}</div>
        <div>
          <div className="">
            <div className="flex justify-between mt-10">
              <div className="w-full ">
                <label
                  htmlFor=""
                  className={`text-labels text-sm ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}
                >
                  {detailsTranslation.firstName[lang]}*
                </label>
                <input
                  type="text"
                  name="first_name"
                  className="block w-full text-lg bg-popcard border-b border-inputBottom focus:outline-none"
                  placeholder={detailsTranslation.firstName[lang]}
                  id="fname"
                  onChange={HandleChange}
                  defaultValue={collaborator?.first_name}
                />
              </div>
              <div className="w-full ml-[24px]">
                <label
                  htmlFor=""
                  className={`text-labels text-sm ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}
                >
                  {detailsTranslation.lastName[lang]}*
                </label>
                <input
                  type="text"
                  name="last_name"
                  className="block w-full text-lg bg-popcard border-b border-inputBottom focus:outline-none"
                  placeholder={detailsTranslation.lastName[lang]}
                  id="lname"
                  onChange={HandleChange}
                  defaultValue={collaborator?.last_name}
                />
              </div>
            </div>

            {restriction?.show_company_name && (
              <div className="mt-5">
                <label
                  htmlFor=""
                  className={`text-labels text-sm ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}
                >
                  {detailsTranslation.company?.[lang]}
                </label>
                {restriction?.restrict_company_name ? (
                  <div className="w-full text-lg">
                    {restriction?.company_name?.[lang]}
                  </div>
                ) : (
                  <input
                    type="text"
                    name="company"
                    className="block w-full text-lg bg-popcard border-b border-inputBottom focus:outline-none"
                    placeholder={detailsTranslation.company?.[lang]}
                    id="post_title"
                    onChange={HandleChange}
                    defaultValue={collaborator?.company?.[lang]}
                  />
                )}
              </div>
            )}

            <div className="mt-5">
              <label
                htmlFor=""
                className={`text-labels text-sm ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}
              >
                {detailsTranslation.position?.[lang]}
              </label>
              <input
                type="text"
                name="title"
                className="block w-full text-lg bg-popcard border-b border-inputBottom focus:outline-none"
                placeholder={detailsTranslation.position?.[lang]}
                id="post_title"
                onChange={HandleChange}
                defaultValue={collaborator?.title?.[lang]}
              />
            </div>

            <div className="mt-8" id="numbers_area">
              <label
                htmlFor=""
                className={`text-labels text-sm ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}
              >
                {detailsTranslation.mobileNumber[lang]}*
              </label>
              {phoneList?.map((phone, i) => {
                return (
                  <Phone
                    phoneData={phone}
                    onDelete={onDelete}
                    index={i}
                    key={phone._id}
                    handlePhoneChange={handlePhoneChange}
                  />
                );
              })}
            </div>
            <div
              className={`flex items-center text-lg text-labels gap-2 font-bold  mt-7 cursor-pointer ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}
              onClick={handleAdd}
            >
              <AiOutlinePlusCircle />
              {detailsTranslation.addMobileNumber[lang]}
            </div>
          </div>
        </div>
      </div>

      <div className="button-container">
        <button
          className={`${safe ? "btn-fields btn-active" : "btn-fields"} ${lang === 'ar' ? '!font-readexRegular' : ''}`}
          id="btn-next"
          onClick={
            edit
              ? (e) => {
                handleSubmit(e);
                setOpenEditPopup(false);
              }
              : handleSubmit
          }
          disabled={!safe}
        >
          {edit ? (
            <img
              src="/assets/img/loaders/loading.gif"
              style={{
                display: loading ? undefined : "none",
                padding: "2px",
              }}
              width="23px"
              alt=""
              className="ml-[45%]"
            />
          ) : null}
          {loading
            ? null
            : edit
              ? detailsTranslation?.buttonSave[lang]
              : detailsTranslation?.buttonNext[lang]}
        </button>
      </div>
    </section>
  );
};

export default Details;
