import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditButton from "../EditButton";
import EditComponants from "../EditComponants";
import useLoggedStatus from "../../../hooks/useLoggedStatus";
import useCollaboratorRestriction from "../../../hooks/useCollaboratorRestriction";
import EmptyContent from "../EmptyContent";
import { translation } from "../../../translation";
import useTracking from "../../../hooks/useTracking";

const ShowLinks = () => {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const [pdfPath, setPdfPath] = useState()
  const initialEditId = 6;

  const logged = useLoggedStatus();
  const restriction = useCollaboratorRestriction();
  const { sendTracking } = useTracking();

  let links = collaborator?.links;
  if (restriction?.restrict_links) {
    links = restriction?.links;
  }

  if (!logged && !links?.length > 0) {
    return null;
  }

  const handleTracking = (url, title) => {
    sendTracking({
      action: 101,
      collaborator_id: collaborator.collaborator_id,
      componant: "Links",
      url: url,
      title: title,
    });
  };

  return (
    <>
      {restriction?.show_links && (
        <section className="mt-9">
          <div className="flex justify-between items-center mb-[3vh]">
            <h2 className={`text-xl ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
              {translation?.sectionTitle?.links?.[lang]}
            </h2>
            {logged && (
              <EditButton
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
              />
            )}
          </div>
          {logged &&
            !restriction?.restrict_links &&
            collaborator.links?.length === 0 && (
              <EmptyContent
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
                sectionTitle={translation?.sectionTitle?.links?.[lang]}
              />
            )}
          <div className="flex-row">
            {links?.map((link) => (
              <div
                key={link._id}
                className="flex items-center mt-[1.1rem] gap-4"
                onClick={() => handleTracking(link?.url, link?.title)}
              >
                <div
                  className={`${link?.thumb ? 'bg-cover' : 'bg-contain' } bg-no-repeat bg-center min-w-[3.5rem] min-h-[3.5rem] bg-white shadow-lg`}
                  style={{
                    backgroundImage: link?.thumb
                    ? `url(${process.env.REACT_APP_IMAGE_PATH}${link.thumb})`
                    : `url(../assets/img/icons/url.svg)`
                  }}
                />

                {link.type === "pdf" ? (
                  <div
                    className="text-black flex w-full cursor-pointer"
                    onClick={() => {
                      setComponantToEditId(14);
                      setOpenEditPopup(true);
                      setPdfPath(link.url)
                    }}
                  >
                    <div>
                      <h3 className={`text-lg font-bold ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`} >
                        {link.title?.[lang]}
                      </h3>
                      <p className="text-sm text-[#795656]">
                        {link.description?.[lang]}
                      </p>
                    </div>
                    <img
                      className={lang === 'ar' ? 'mr-auto rotate-180' : 'ml-auto'}
                      src="../assets/img/icons/arrow-right.svg"
                    />
                  </div>
                ) : (
                  <a
                    href={
                      link?.url && link?.url.startsWith("http")
                        ? link?.url
                        : `http://${link?.url}`
                    }
                    className="text-black flex w-full"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <h3 className={`text-lg font-bold ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
                        {link.title?.[lang]}
                      </h3>
                      <p className="text-sm text-[#795656]">
                        {link.description?.[lang]}
                      </p>
                    </div>
                    <img
                      className={lang === 'ar' ? 'mr-auto rotate-180' : 'ml-auto'}
                      src="../assets/img/icons/arrow-right.svg"
                    />
                  </a>
                )}
                {/* <a
                  // href={link?.url}
                  href={
                    link.type === 'url' ?
                    link?.url && link?.url.startsWith("http")
                      ? link?.url
                      : `http://${link?.url}`
                    : `${process.env.REACT_APP_PUBLIC_API}/pdfs/${link.url}`
                  }
                  className="text-black flex w-full"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                {/* <div>
                    <h3 className={`text-lg font-bold ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
                      {link.title?.[lang]}
                    </h3>
                    <p className="text-sm text-[#795656]">
                      {link.description?.[lang]}
                    </p>
                  </div>
                  <img
                    className="ml-auto"
                    src="../assets/img/icons/arrow-right.svg"
                  />
                </a>
                </div> */}
              </div>
            ))}
          </div>
          {logged && openEditPopup && (
            <EditComponants
              componantId={componantToEditId}
              setOpenEditPopup={setOpenEditPopup}
            />
          )}

          {openEditPopup && pdfPath && (
            <EditComponants
              componantId={componantToEditId}
              setOpenEditPopup={setOpenEditPopup}
              pdfPath={pdfPath}
            />
          )}
        </section>
      )}
    </>
  );
};

export default ShowLinks;
