import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useLoggedStatus from "../../../hooks/useLoggedStatus";
import About from "../../onBoarding/About";
import EditButton from "../EditButton";
import EditComponants from "../EditComponants";
import useCollaboratorRestriction from "../../../hooks/useCollaboratorRestriction";
import EmptyContent from "../EmptyContent";
import { translation } from "../../../translation";

const ShowAbout = () => {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const [moreAbout, setMoreAbout] = useState(false);
  const initialEditId = 1;

  const logged = useLoggedStatus();

  const restriction = useCollaboratorRestriction();

  let about = collaborator.about?.[lang];

  if (restriction?.restrict_about) {
    about = restriction.about?.[lang];
  }

  const aboutSplit = (text) => {
    var first_slice = "";
    var second_slice = "";
    if (text?.length <= 200) {
      first_slice = text;
    } else {
      first_slice = text?.slice(0, 200);
      second_slice = text?.slice(200, moreAbout.length);
    }
    return (
      <>
        {(moreAbout ? text : first_slice).split("\n").map((val, i) => {
          return (
            <div>
              {val}{" "}
              {(moreAbout ? text : first_slice).split("\n").length - 1 === i ? (
                text.length > 200 ? (
                  <>
                    {!moreAbout ? "..." : null}
                    <button
                      className={`text-labels text-xl underline ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}
                      onClick={() => {
                        setMoreAbout(!moreAbout);
                      }}
                    >
                      {moreAbout ? "Lire moins" : "Lire plus"}
                    </button>
                  </>
                ) : null
              ) : (
                <br />
              )}
            </div>
          );
        })}
      </>
    );
  };

  if (!logged && about == "") {
    return null;
  }
  return (
    <>
      {(restriction?.show_about || (!logged && about !== "")) && (
        <section className="mt-9">
          <div className="flex justify-between items-center mb-[3vh]">
            <h2 className={`text-xl ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
              {translation?.sectionTitle?.about?.[lang]}
            </h2>
            {logged && (
              <EditButton
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
              />
            )}
          </div>
          {logged &&
            !restriction?.restrict_about &&
            collaborator.about?.[lang] == "" && (
              <EmptyContent
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
                sectionTitle={translation?.sectionTitle?.about?.[lang]}
                isPlural={true}
              />
            )}
          <div className="text-[18px] w-full tracking-[-.2px] leading-[27px]">
            {about && aboutSplit(about)}
          </div>
          {/* <p className="text-lg mt-3">
            {restriction?.restrict_about
              ? restriction?.about?.[lang]
              : collaborator?.about?.[lang]}
          </p> */}
        </section>
      )}
      {logged && openEditPopup && (
        <EditComponants
          componantId={componantToEditId}
          setOpenEditPopup={setOpenEditPopup}
        />
      )}
    </>
  );
};

export default ShowAbout;
