import React, { useState, useRef } from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useUploadProfileImageMutation } from '../../redux/appSlices/collaborator/collaboratorApiSlice';
import { useSelector } from 'react-redux';
import { translation } from '../../translation';

const CropProfilePic = ({ setOpenEditPopup, imageSrc, setOpenPopup, setProfileLoading }) => {
  const cropperRef = useRef();
  const [imageElement, setImageElement] = useState();

  const lang = useSelector((state) => state.collaborator.collaboratorInfo.language)

  const [uploadProfileImage] = useUploadProfileImageMutation();

  const onCrop = () => {
    setImageElement(cropperRef?.current);
  };

  const onSubmit = () => {
    const cropper = imageElement?.cropper;
    const data = cropper.getCroppedCanvas().toDataURL();
    setOpenEditPopup(false)
    uploadProfileImage({ 'base64': data }).unwrap().then((res) => {
      setOpenPopup(false)
      setOpenEditPopup(false)
      setProfileLoading(false)
    })
  };


  return (
    <section className="pt-[7vh] mx-5">
      {imageSrc && (
        <>
          <div className='min-h-[75vh]'>


            <p className='mb-5 pl-[4vh] text-[18px]'>{translation.cropImage.title?.[lang]}</p>
            <div className='relative w-full h-[60vh]'>
              <Cropper
                src={URL.createObjectURL(imageSrc)}
                crop={onCrop}
                ref={cropperRef}
                viewMode={1}
                aspectRatio={134 / 168}
              />
            </div>
          </div>
          <div className="button-container">
            <button className='btn-fields-terminer btn-active' onClick={onSubmit}>
              {translation.cropImage.button?.[lang]}
            </button>
          </div>
        </>
      )}
    </section>
  )
}

export default CropProfilePic