import React, { useEffect, useMemo, useRef, useState } from 'react'
import useOutsideClosePopup from '../../hooks/useOutsideClosePopup';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '../../redux/appSlices/collaborator/collaboratorSlice';
import useLoggedStatus from '../../hooks/useLoggedStatus';
import { usePatchCollaboratorMutation } from '../../redux/appSlices/collaborator/collaboratorApiSlice';

const LanguageSelect = () => {

  const dispatch = useDispatch()

  const options = [
    { value: "fr", label: "FR", icon: "/assets/img/icons/fr-flag.svg" },
    { value: "en", label: "EN", icon: "/assets/img/icons/en-flag.svg" },
    { value: "ar", label: "AR", icon: "/assets/img/icons/ar-flag.png" },
    // { value: "tz", label: "ⵣ", icon: "/assets/img/icons/tz-flag.png" },
  ];

  // let lang = localStorage.getItem('lang') ?? useSelector((state) => state.collaborator.collaboratorInfo.language) ?? 'fr'
  // const localLang = localStorage.getItem('lang')
  
  // const collabLang = useSelector((state) => state.collaborator.collaboratorInfo.language)
  let lang = useSelector((state) => state.collaborator.collaboratorInfo.language)

 

  const logged = useLoggedStatus();
  const collabId = useSelector((state) => state.collaborator.collaboratorInfo._id)

  // const [lang, setLanguage] = useState(localLang ? localLang : collabLang)
  // const [selectedValue, setSelectedValue] = useState(options.findIndex(option => option.value === lang))
  const [selectedValue, setSelectedValue] = useState()
  // const [optionValue, setOptionValue] = useState(selectedValue === 1 ? 0 : 1)
  const [openOption, setOpenOption] = useState(false)


  const wrapperRef = useRef(null);
  useOutsideClosePopup(wrapperRef, setOpenOption);

  // const [patchCollaborator] = usePatchCollaboratorMutation()

  useEffect(() => {
    // lang = localLang ? localLang : collabLang
    setSelectedValue(options.findIndex(option => option.value === lang))
  }, [lang])

  // useEffect(() => {
  //   if (localLang) {
  //     lang = localLang;
  //     dispatch(setLang(localLang));
  //   } else if (collabLang) {
  //     lang = collabLang;
  //   }

  //   if (lang === 'fr') {
  //     setSelectedValue(0);
  //   } else if ((lang === 'en')) {
  //     setSelectedValue(1);
  //   } else {
  //     setSelectedValue(2);
  //   }
  // }, [collabLang]);

  // useEffect(() => {
  //   dispatch(setLang(options?.[selectedValue].value))
  //   localStorage.setItem('lang', options?.[selectedValue].value)

  //   if (logged && collabLang !== localStorage.getItem('lang')) {
  //     patchCollaborator({
  //       id: collabId,
  //       content: {
  //         language: options?.[selectedValue].value
  //       }
  //     })

  //   }
  // }, [selectedValue])


  // ################### DEV AREA ###################
  // useEffect(() => {
  //   console.log("lang ===> ", lang)
  //   // console.log("localLang ===> ", localLang)
  //   console.log("collabLang ===> ", collabLang)

  // }, [collabLang])
  // ################### END DEV AREA ###################

  return (
    <div ref={wrapperRef} className='ml-auto'>
      <button
        className='bg-white bg-opacity-50 text-base flex justify-around ml-auto border border-white rounded-[0.25rem] items-center w-16 h-8'
        onClick={() => { setOpenOption(!openOption) }}
      >
        <img src={options?.[selectedValue]?.icon} className='h-[14px] w-[14px]' />
        <span>{options?.[selectedValue]?.label}</span>
        <img className={openOption ? 'rotate-180' : ''} src='/assets/img/icons/down-filled.svg' />

      </button>
      {
        openOption &&
        options
          .filter((option) => option.value !== lang)
          .map((opt) => (
            <button
              className='bg-white bg-opacity-50 text-base flex justify-around ml-auto border border-white rounded-[0.25rem] items-center w-16 h-8 mt-1'
              onClick={() => {
                setOpenOption(false)
                setSelectedValue(options.findIndex(option => option.value === opt.value));
                dispatch(setLang(opt.value))
              }}
            >
              <img src={opt?.icon} className='h-[14px] w-[14px]' />
              <span>{opt?.label}</span>
            </button>
          ))
      }
    </div>
  )
}

export default LanguageSelect