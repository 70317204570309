import React from "react";
import Lottie from "lottie-react";
import animationData from "../../lotties/pop-card-animation.json";

const LottieCard = () => {
  return (
    <div className="mt-[4vh] w-full max-w-[400px] h-[200px] my-0 mx-auto">
      <Lottie animationData={animationData} height={200} width={400} loop={true} />
    </div>
  );
};

export default LottieCard;
