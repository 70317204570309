import md5 from 'md5';

const useUserToken = () =>  {
  const getUserToken = () => {
    let storedUserToken = localStorage.getItem('user_token');
    if (!storedUserToken) {
      storedUserToken = md5(Math.random().toString());
      localStorage.setItem('user_token', storedUserToken);
    }
    return storedUserToken === null ? undefined : storedUserToken
  }

  return {
    getUserToken
  };
}

export default useUserToken