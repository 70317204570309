import React from "react";
// import { useGlobalState } from "../..";
import thanksIcon from "../../assets/merci.svg";
import { useSelector } from "react-redux";
import { translation } from "../../translation";

const IdentificationFormDone = ({ close, data }) => {
  // const [lang] = useGlobalState("lang_tran");
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  return (
    <div className="flex items-center flex-col justify-center mt-[3rem]">
      <img src={thanksIcon} alt="merci" className="object-contain w-[150px]" />
      <h6 className={`text-[45px] ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>{translation.IdentificationFormDone.thanks[lang]}</h6>
      <p className={`items-center text-center mt-2 font-semibold ${lang === 'ar' ? 'font-readexRegular' : 'font-SteradianRegular'}`}>
        {translation.IdentificationFormDone.thanksText[lang]} {data.first_name} {translation.IdentificationFormDone.with[lang]}
      </p>
      <div className="buttonWrapper mt-[2rem]">
        <button className={`btn btn-primary ${lang === 'ar' ? 'font-readexRegular' : 'font-SteradianRegular'}`} onClick={close}>
          {translation.IdentificationFormDone.button[lang]}
        </button>
      </div>
    </div>
  );
};

export default IdentificationFormDone;
