import { createSlice } from "@reduxjs/toolkit";

const collaboratorSlice = createSlice({
  name: "collaborator",
  initialState: {
    collaboratorInfo: {
      collaborator_id: null,
      onboarding_step: null,
    },
  },
  reducers: {
    setOnboardingStep: (state, action) => {
      // state.collaboratorInfo.collaborator_id = action.payload.collaborator_id;
      state.collaboratorInfo.onboarding_step = action.payload.onboarding_step;
    },
    getCollaborator: (state, action) => {
      state.collaboratorInfo = action.payload;
    },

    setLang: (state, action) => {
      state.collaboratorInfo.language = action.payload
    }
  },
});

export const { setOnboardingStep, getCollaborator, setLang } = collaboratorSlice.actions;

export default collaboratorSlice.reducer;

export const selectCurrentOnboardingStep = (state) =>
  state.collaborator.collaboratorInfo.onboarding_step;
// export const selectCurrentCollaborator = (state) => state.collaborator.collaboratorInfo
