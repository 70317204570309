import { apiSlice } from "../../api/apiSlice";

export const collaboratorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get Collaborator data
    getCollaborator: builder.query({
      query: (id) => `/collaborators/${id}`,
      providesTags: ["collaborator"],
    }),

    // Get vCard for a Collaborator
    // query: (id) => `/collaborators/${id}/vcard`,
    getvCard: builder.query({
      query: (id) => `/vcards/${id}`,
    }),

    // Update Collaborator data
    patchCollaborator: builder.mutation({
      query: ({ id, content }) => ({
        url: `/collaborators/${id}`,
        method: "PATCH",
        body: { ...content },
      }),
      invalidatesTags: ["collaborator"],
    }),

    // Upload collaborator image profile ***Profile picture BASE64***
    uploadProfileImage: builder.mutation({
      query:(base) => ({
        url: `/collaborators/profile-image-64`,
        method: 'POST',
        body: {...base},
      }),
      invalidatesTags: ["collaborator"],
    }),

    // Upload Collaborator Image from ***ANY IMAGE***
    uploadImage: builder.mutation({
      query: (imageFile) => {
        const bodyFormData = new FormData();
        bodyFormData.append("image", imageFile);
        return {
          url: `/collaborators/images`,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
    }),

    // Upload Collaborator Image from ***image section***
    uploadCollaboratorImage: builder.mutation({
      query: (imageFile) => {
        const bodyFormData = new FormData();
        bodyFormData.append("image", imageFile);
        return {
          url: `/collaborators/upload-image`,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: ["collaborator"],
    }),

    // DELETE Collaborator Image from ***image section***
    deleteCollaboratorImage: builder.mutation({
      query: (content) => ({
        url: `collaborators/remove-image`,
        method: "DELETE",
        body: { ...content },
      }),
      invalidatesTags: ["collaborator"],
    }),

    changeCollabPass: builder.mutation({
      query:(base) => ({
        url: `/collaborators/change-password`,
        method: 'POST',
        body: {...base},
      }),
    }),

    checkSlug: builder.mutation({
      query: (content) => ({
        url: `/collaborators/check-slug`,
        method: 'POST',
        body: {...content},
      })
    }),

    postForgotPassword: builder.mutation({
      query: (content) => ({
        url: `/collaborators/reset-password`,
        method: 'POST',
        body: {...content},
      })
    }),

    //Create new Identification
    postCreateIdentif: builder.mutation({
      query: (content) => ({
        url: `/identifications`,
        method: 'POST',
        body: {...content},
      })
    }),

    patchCreateIdentif: builder.mutation({
      query: ({identifId, content}) => ({
        url: `/identifications/${identifId}`,
        method: 'PATCH',
        body: {...content},
      })
    }),

    tracking: builder.mutation({
      query: (content) => ({
        url: `/trackings`,
        method: 'POST',
        body: {...content}
      })
    }),

    // upload picture publicaly
    uploadPublic: builder.mutation({
      query: (imageFile) => {
        const bodyFormData = new FormData();
        bodyFormData.append("file", imageFile);
        return {
          url: `/upload`,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
    }),

    // get contact lock
    getContactLock: builder.query({
      query: (id) => `/collaborators/${id}/contact-lock`,
      // providesTags: ["collaborator"],
    }),

    //create contact lock
    postCreateLock: builder.mutation({
      query: (id) => ({
        url: `/collaborators/${id}/contact-lock`,
        method: 'POST',
      }),
    }),

    //Check contact lock code
    checkContactLock: builder.mutation({
      query: ({id, content}) => ({
        url: `/collaborators/${id}/contact-lock`,
        method: 'PATCH',
        body: {...content}
      })
    }),

    //upload && delete pdf in links
    uploadPdf: builder.mutation({
      query: (pdfFile) => {
        const bodyFormData = new FormData();
        bodyFormData.append("pdf", pdfFile);
        return {
          url: `/medias/pdfs`,
          method: "POST",
          body: bodyFormData,
        };
      },
    }),

    deleteCollaboratorpdf: builder.mutation({
      query: (content) => ({
        url: `/medias/pdfs`,
        method: "DELETE",
        body: { ...content },
      }),
    }),

    // Get an email with the current Qr code
    downloadQrCode: builder.query({
      query: (id) => `/collaborators/${id}/generateQrCode`,
    }),




    // updateProfilePicture: builder.mutation({
    //   query: (imageFile) => {
    //     const bodyFormData = new FormData();
    //     bodyFormData.append("profile-image", imageFile);
    //     return {
    //       url: `/collaborators/profile-image`,
    //       method: "POST",
    //       body: bodyFormData,
    //       formData: true,
    //     };
    //   },
    //   invalidatesTags: ["collaborator"],
    // }),
  }),
});

export const {
  useGetCollaboratorQuery,
  useGetvCardQuery,
  usePatchCollaboratorMutation,
  useUploadProfileImageMutation,
  useUploadImageMutation,
  useUploadCollaboratorImageMutation,
  useDeleteCollaboratorImageMutation,
  useChangeCollabPassMutation,
  useCheckSlugMutation,
  usePostForgotPasswordMutation,
  usePostCreateIdentifMutation,
  usePatchCreateIdentifMutation,
  useTrackingMutation,
  useUploadPublicMutation,
  useGetContactLockQuery,
  usePostCreateLockMutation,
  useCheckContactLockMutation,
  useUploadPdfMutation,
  useDeleteCollaboratorpdfMutation,
  useDownloadQrCodeQuery
  // useUpdateProfilePictureMutation,
} = collaboratorApiSlice;
