import React, { useCallback, useEffect, useRef, useState } from "react";
// import { useGlobalState } from "..";
// import { add, getTime } from "date-fns";
// import AppServices from "../services/AppServices";
import Switch from "../../UI/Switch";
import CopyToClipboard from "./CopyToClipboard";
import { translation } from "../../../translation";
import { useSelector } from "react-redux";
import { useGetContactLockQuery, usePatchCollaboratorMutation, usePostCreateLockMutation } from "../../../redux/appSlices/collaborator/collaboratorApiSlice";

const SettingCode = () => {

  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  // const [trans] = useGlobalState("onboarding_trans");
  // const [refresh, setRefresh] = useGlobalState("refresh");
  const [firstCheck, setFirstCheck] = useState(false);

  const [activeLock, setActiveLock] = useState(collaborator?.contact_locked);
  const [contactLock, setContactLock] = useState(null)
  const [countdown, setCountdown] = useState(-1.5);

  const [postCreateLock] = usePostCreateLockMutation()
  const [patchCollaborator] = usePatchCollaboratorMutation();
  const {data} = useGetContactLockQuery(collaborator._id, {
    skip:!collaborator?.contact_locked,
  })


  const tickRef = useRef(false);

  useEffect(() => {
    if(data) {
      setContactLock(data.data.contactLock)
    }
  }, [data])


  // const changeLock = (current_status) => {
    // AppServices.post(
    //   {
    //     action: 5,
    //     data: {
    //       contact_locked: current_status,
    //     },
    //   },
    //   "/app"
    // ).then((response) => {
    //   setRefresh(refresh + 1);
    // });
  // };

  useEffect(() => {
    const seconds = dateDifferenceInSeconds(contactLock?.createdAt);

    if (seconds && !firstCheck) {
      if (seconds >= 900 && collaborator?.contact_locked) {
        // changeLock(true);
        generateLock("from collaborator contact lock");
        setCountdown(900);
      } else {
        setCountdown(
          secondsLeftToReach15Minutes(contactLock?.createdAt)
        );
      }
      setFirstCheck(true);
    }
  }, [contactLock]);

  useEffect(() => {
    if (tickRef.current) return;

    let intervalId;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        tickRef.current = true;
        setCountdown((prevCountdown) => prevCountdown - 1);
        tickRef.current = false;
      }, 1000);
    } else if (countdown !== -1.5 && activeLock === true) {
      // changeLock(true);
      generateLock("from countdown");
      setCountdown(900);
    }

    return () => clearInterval(intervalId);
  }, [countdown]);

  const secondsLeftToReach15Minutes = (createdOn) => {
    const createdDate = new Date(createdOn);
    const nowUTC = new Date();
    const timeDifference = nowUTC - createdDate;
    const elapsedSeconds = Math.floor(timeDifference / 1000);
    const totalSeconds = 15 * 60;
    const secondsLeft = totalSeconds - elapsedSeconds;
    return secondsLeft > 0 ? secondsLeft : 0;
  };

  function secondsToFormat(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  }

  const dateDifferenceInSeconds = (createdOn) => {
    const createdDate = new Date(createdOn);
    const nowUTC = new Date();
    const timeDifference = nowUTC - createdDate;
    return Math.floor(timeDifference / 1000);
  };

  const generateLock = (s) => {
    // AppServices.post(
    //   {
    //     action: 4,
    //   },
    //   "/app"
    // ).then((response) => {
    //   setRefresh(refresh + 1);
    //   setCountdown(900);
    // });
    postCreateLock(collaborator._id).unwrap().then((res) => {
      setContactLock(res.data.contactLock);
      setCountdown(900);
    })
    
  };

  const handleContactLockChange = () => {
    if (!activeLock) {
      generateLock()
    } else {
      patchCollaborator({
        id: collaborator._id,
        content: {
          contact_locked : false
        }

      })
    }
    // changeLock(!activeLock);
    setActiveLock(!activeLock);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex gap-5">
          <img
            src="/assets/img/icons/v-icon.svg"
            alt=""
            className="setting-icon"
          />
          <div className="text-[17px] max-w-[250px]">
            {translation.settings["temporary-code-section"].title[lang]}
          </div>
        </div>
        <Switch
          checked={activeLock}
          onChange={handleContactLockChange}
          name="checkedA"
          inputProps={{ "aria-label": "controlled" }}
          value={activeLock}
        />
      </div>
      <div className="mt-[-10px] ml-[43px] overflow-hidden">
        {activeLock ? (
          <div className="text-[16px] text-[#29A254]">
            {translation.settings["temporary-code-section"].active[lang]}
          </div>
        ) : (
          <div className="text-[16px] text-[#909391]">
            {translation.settings["temporary-code-section"].inactive[lang]}
          </div>
        )}
        {/* Votre code temporaire */}
        {activeLock && (
          <>
            <div className="text-[18px] text-[#4E3131] mt-1">
              {translation.settings["temporary-code-section"].subtitle[lang]}
            </div>
            <div className=" text-black mt-1 flex justify-between">
              <div className="text-[22px] flex items-center gap-2">
                <CopyToClipboard value={contactLock?.password} />
                <span className="text-black mt-2">
                  {contactLock?.password?.replace(/\d{2}(?=\d)/g, "$& ")}
                </span>
              </div>

              <p className="flex items-center">
                ({secondsToFormat(countdown)}
                <img
                  src="/assets/img/icons/clock.svg"
                  alt=""
                  className="w-[15px] h-[15px] ml-1"
                />
                )
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SettingCode;
