import React from "react";
import Lottie from "lottie-react";
import animationData from "../../lotties/changeLangAnimation.json";

const LottieChangeLang = () => {
  return (
    <div style={{ marginTop: "4vh", position: "absolute", left: 0, right: 0 }}>
      <Lottie animationData={animationData} style={{height : '350px'}} loop={true} />
    </div>
  );
};

export default LottieChangeLang;
