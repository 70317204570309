export const onboardingTranslation = {
  buttonPass: {
    fr: "Passer",
    en: "Skip",
    ar: "تخطي",
  },
};

export const translation = {
  IdentificationFormDone: {
    thanks: {
      fr: "Merci !",
      en: "Thank you !",
      ar: "شكراً !"
    },

    thanksText: {
      fr: "Vos informations ont été transmises à",
      en: "Your information has been sent to",
      ar: "تم إرسال معلوماتك إلى"
    },

    with: {
      fr: "avec succès.",
      en: "successfully.",
      ar: "بنجاح."
    },

    button: {
      fr: "Fermer la fenêtre",
      en: "Close the window",
      ar: "إغلاق النافذة"
    },
  },
  saveErrorMessage: {
    fr: "Erreur lors de l'enregistrement, veuillez réessayer",
    en: "Error saving data, please try again",
    ar: "خطأ في حفظ البيانات، يرجى المحاولة مرة أخرى",
  },
  updateErrorMessage: {
    fr: "Erreur lors de la mise à jour, veuillez réessayer",
    en: "Error updating data, please try again",
    ar: "خطأ في تحديث البيانات، يرجى المحاولة مرة أخرى",
  },
  header: {
    annuler: {
      fr: "Annuler",
      en: "Cancel",
      ar: "إلغاء",
    },
    enregistrer: {
      fr: "Enregistrer",
      en: "Save",
      ar: "حفظ"
    },
  },
  orderSection: {
    title: {
      fr: "Réorganiser les champs",
      en: "Rearrange fields",
      ar: "إعادة ترتيب الحقول",
    },
    subtitle: {
      fr: "Déplacez les blocs dans l'ordre que vous souhaitez afficher vos informations",
      en: "Move the blocks in the order you want to display your information",
      ar: "حرك المربعات بالترتيب الذي تريد عرض معلوماتك فيه",
    },
    button: {
      fr: "Sauvegarder",
      en: "Save",
      ar: "حفظ",
    },
  },
  ContactLock: {
    title: {
      fr: "Code d'identification",
      en: "Identification code",
      ar: "رمز التعريف",
    },
    subtitle: {
      fr: "Saisir le mot de passe temporaire",
      en: "Enter the temporary password",
      ar: "أدخل كلمة المرور المؤقتة",
    },
    error: {
      fr: "Le mot de passe est incorrect !",
      en: "Password incorrect!",
      ar: "كلمة المرور غير صحيحة!",
    },
    button: {
      fr: "Valider",
      en: "Submit",
      ar: "تأكيد",
    },
  },
  loginPage: {
    loginError: {
      fr: 'Le nom d\'utilisateur ou le mot de passe est incorrect. Veuillez réessayer ou cliquer sur « Mot de passe oublié ».',
      en: 'The username or password is incorrect. Please try again or click on "Forgot password".',
      ar: 'اسم المستخدم أو كلمة المرور غير صحيحة. يرجى المحاولة مرة أخرى أو النقر على "نسيت كلمة المرور".'
    },
    greeting: {
      fr: "Bonjour",
      en: "Hello",
      ar: "مرحبًا",
    },
    loginTitle: {
      fr: "Se connecter",
      en: "Login",
      ar: "تسجيل الدخول",
    },
    delete: {
      fr: "Supprimer",
      en: "Delete",
      ar: "حذف",
    },
    buttonSave: {
      fr: "Sauvegarder",
      en: "Save",
      ar: "حفظ",
    },
    buttonLogout: {
      fr: "Se déconnecter",
      en: "Logout",
      ar: "تسجيل الخروج",
    },
    loginDescription: {
      fr: "Veuillez vous authentifier afin de modifier vos informations.",
      en: "Please login in order to modify your information.",
      ar: "يرجى تسجيل الدخول لتعديل معلوماتك.",
    },
    email: {
      fr: "Email",
      en: "Email",
      ar: "البريد الإلكتروني",
    },
    pass: {
      fr: "Mot de passe",
      en: "Password",
      ar: "كلمة المرور",
    },
    passOublie: {
      fr: "Mot de passe oublié ?",
      en: "Forgot password?",
      ar: "هل نسيت كلمة مرورك؟",
    },
  },

  "edit-information-login": {
    bonjour: {
      fr: "Bonjour",
      en: "Hello",
      ar: "مرحبًا",
    },
    ressaye: {
      fr: "n’est associée à aucun compte. Veuillez réessayer",
      en: "is not associated with any account. Please try again",
      ar: "غير مرتبط بأي حساب. يرجى المحاولة مرة أخرى",
    },
    topr: {
      fr: "Un mot de passe temporaire vient de vous être envoyé par mail à l’adresse",
      en: "A temporary password has just been sent to you by email to the address",
      ar: "تم إرسال كلمة مرور مؤقتة إليك عبر البريد الإلكتروني إلى العنوان",
    },
    subtitle: {
      fr: "Veuillez saisir le nom d'utilisateur rattaché à votre compte.",
      en: "Please enter the username attached to your account",
      ar: "يرجى إدخال اسم المستخدم المرتبط بحسابك.",
    },
    retour: {
      fr: "Retour",
      en: "Back",
      ar: "عودة",
    },
    buttonSend: {
      fr: "Envoyer la demande",
      en: "Send request",
      ar: "إرسال الطلب",
    },
    pleaseLogin: {
      fr: "Veuillez vous authentifier afin de modifier vos informations.",
      en: "Please login in order to modify your information.",
      ar: "يرجى تسجيل الدخول لتعديل معلوماتك.",
    },
    pass: {
      fr: "Mot de passe",
      en: "Password",
      ar: "كلمة المرور",
    },
    "pass-oublie": {
      fr: "Mot de passe oublié ?",
      en: "Forgot password?",
      ar: "هل نسيت كلمة مرورك؟",
    },
  },

  identificationForm: {
    title: {
      fr: "Partager vos informations avec",
      en: "Share your information with",
      ar: "مشاركة معلوماتك مع",
    },
    name: {
      fr: "Prénom",
      en: "First name",
      ar: "الاسم الأول",
    },
    lastName: {
      fr: "Nom",
      en: "Last name",
      ar: "الاسم الأخير",
    },
    button: {
      fr: "Partager",
      en: "Share",
      ar: "مشاركة",
    },
    partager: {
      fr: "Pressé(e)? Partagez une photo de votre carte de visite en quelques secondes",
      en: "In a hurry? Share a picture of your business card in seconds",
      ar: "في عجلة من أمرك؟ شارك صورة لبطاقة العمل الخاصة بك في ثوانٍ",
    },
    sending: {
      en: "Sending...",
      fr: "Envoi...",
      ar: "جار الإرسال...",
    },
  },

  mainButton: {
    showSettings: {
      fr: "Accéder aux Paramètres",
      en: "Go to Settings",
      ar: "انتقل إلى الإعدادات",
    },
    updateMyContact: {
      fr: "Mettre à jour ma fiche de contact",
      en: "Update my contact sheet",
      ar: "تحديث بطاقة الاتصال الخاصة بي",
    },
    addContact: {
      fr: "Ajouter aux contacts",
      en: "Add to contacts",
      ar: "إضافة إلى جهات الاتصال",
    },
    identifyContact: {
      fr: "Partager mes infos",
      en: "Share my information",
      ar: "مشاركة معلوماتي",
    },
  },

  qrCode: {
    secondeClick: {
      fr: "Vérifiez votre boite mail vos QR-Codes Hors ligne/En ligne  ont été envoyés",
      en: "Check your mail your Offline/Online QR-Codes have been sent",
      ar: "تحقق من بريدك الإلكتروني، فقد تم إرسال أكواد QR الخاصة بك دون اتصال/اتصال بالإنترنت",
    },
  },

  pwa: {
    title: {
      fr: "QR Code en ligne/hors ligne",
      en: "QR Code online/offline",
      ar: "كود QR عبر الإنترنت/غير متصل",
    },
    description: {
      fr: "Veuillez vous authentifier une fois afin de générer vos QR code online/offline. Ils seront accessibles les prochaines fois sans vous connecter.",
      en: "Please authenticate once in order to generate your online/offline QR code. They will be accessible the next time without logging in.",
      ar: "يرجى المصادقة مرة واحدة لإنشاء كود QR الخاص بك عبر الإنترنت/غير متصل. ستكون متاحة في المرة القادمة دون تسجيل الدخول.",
    },
  },

  sectionTitle: {
    settings: {
      fr: "Paramètres",
      en: "Settings",
      ar: "الإعدادات",
      tz: "ⴰⵙⵏⵉⵎⵉⵍ"
    },
    about: {
      fr: "À propos",
      en: "About",
      ar: "نبذة عني",
      tz: "ⴰⵎⵢⴰⵣⵉ"
    },
    contactInfo: {
      fr: "Coordonnées",
      en: "Contact Information",
      ar: "معلومات الاتصال",
      tz: "ⴰⴳⵔⴰⵡ ⵏ ⵉⵙⵎⵓⵏ"
    },
    socials: {
      fr: "Réseaux sociaux",
      en: "Social media",
      ar: "وسائل التواصل الاجتماعي",
      tz: "ⵉⵏⵙⴰⵎⵉⵏ ⵏ ⵜⴳⵎⴷⵉⴷⵉⵏ"
    },
    products: {
      fr: "Produits et services",
      en: "Products and services",
      ar: "المنتجات والخدمات",
      tz: "ⵜⵉⵎⵓⵙⵙⵉⵏ ⴷ ⵜⴰⵏⵙⵓⵔⵉⵏ"
    },
    appointment: {
      fr: "Prise de rendez-vous",
      en: "Appointment booking",
      ar: "حجز المواعيد",
      tz: "ⵜⵓⵏⵥⵉⵥⵜ ⵏ ⵜⵎⵎⵓⵔⵜ"
    },
    links: {
      fr: "Liens et pdfs",
      en: "Links and pdfs",
      ar: "الروابط والملفات",
      tz: "ⵍⵉⵏⴽⵙ ⴷ PDF"
    },
    images: {
      fr: "Images",
      en: "Images",
      ar: "صور",
      tz: "ⵉⵎⴰⵢⵏⵏⵉ"
    },
    videos: {
      fr: "Vidéos",
      en: "Videos",
      ar: "فيديوهات",
      tz: "ⴰⵡⵉⴷⵉⵢⵏ"
    }
  },

  emptyCompo: {
    add: {
      fr: "ajouter",
      en: "add",
      ar: "إضافة",
    },
    addPlural: {
      fr: "Ajouter vos",
      en: "Add your",
      ar: "أضف",
    },
  },

  logoutAnim: {
    title: {
      fr: "Pour vous reconnecter la prochaine fois, swipez cette zone à gauche",
      en: "To reconnect next time, swipe this area to the left",
      ar: "للتسجيل مرة أخرى في المرة القادمة، اسحب هذا الرمز إلى اليسار",
    },

    button: {
      fr: "Ok, c’est noté !",
      en: "Okay, noted!",
      ar: "حسنًا، تم ملاحظة ذلك!",
    },
  },

  settings: {
    title: {
      fr: "Paramètres",
      en: "Settings",
      ar: "الإعدادات",
    },
    "password-change-section": {
      title: {
        fr: "Redéfinir le mot de passe",
        en: "Change your password",
        ar: "تغيير كلمة المرور",
      },
      subtitle: {
        fr: "Merci de saisir votre ancien mot de passe avant d’en choisir un nouveau.",
        en: "Please enter your old password before choosing a new one.",
        ar: "يرجى إدخال كلمة المرور القديمة قبل اختيار كلمة مرور جديدة",
      },
      actuel: {
        fr: "Mot de passe actuel*",
        en: "Current password*",
        ar: "كلمة المرور الحالية*",
      },
      nouveau: {
        fr: "Nouveau mot de passe*",
        en: "New password*",
        ar: "كلمة مرور جديدة*",
      },
      confirmer: {
        fr: "Confirmer le nouveau mot de passe*",
        en: "Confrim new password*",
        ar: "تأكيد كلمة المرور الجديدة*",
      },
      incorrect: {
        fr: "Mot de passe incorrect !",
        en: "Password incorrect!",
        ar: "كلمة المرور غير صحيحة!",
      },
      different: {
        fr: "Les mots de passe ne correspondent pas !",
        en: "Passwords don't match",
        ar: "كلمات المرور لا تتطابق!",
      },
      button: {
        fr: "Sauvegarder",
        en: "Save",
        ar: "حفظ",
      },
    },
    qrCode: {
      fr: "Obtenir mes QR Codes online/offline par mail",
      en: "Receive my QR codes online/offline through email.",
      // ar: "استلام أكواد QR الخاصة بي عبر الإنترنت/غير متصل عبر البريد الإلكتروني",
      ar: "استلام رمز QR الخاصة بي عبر البريد الإلكتروني",
    },
    defaultLang: {
      fr: "Sélectionnez la langue par défaut pour votre profil",
      en: "Select the default language for your profile",
      ar: "اختر اللغة لملفك الشخصي",
    },
    "custom-link-section": {
      title: {
        fr: "Définir un lien public",
        en: "Set a public link",
        ar: "تعيين رابط عام",
      },
      lienactuel: {
        fr: "Lien actuel",
        en: "Current link",
        ar: "الرابط الحالي",
      },
      partager: {
        fr: "Partager avec une rencontre",
        en: "Share with an encounters",
        ar: "مشاركة مع لقاء",
      },
      copier: {
        fr: "Copier l'URL",
        en: "Copy URL",
        ar: "نسخ الرابط",
      },
      other: {
        fr: "Autres",
        en: "Other",
        ar: "أخرى",
      },
      subtitle: {
        fr: "Transformer votre lien crypté en lien public vous permet d’atteindre une plus large audience",
        en: "Turning your encrypted link into a public link allows you to reach a wider audience",
        ar: "تحويل رابطك المشفر إلى رابط عام يسمح لك بالوصول إلى جمهور أوسع",
      },
      lien: {
        fr: "Lien personnalisé",
        en: "Customized link",
        ar: "رابط مخصص",
      },
      error: {
        fr: "Ce lien est déjà pris!",
        en: "This link is already taken!",
        ar: "هذا الرابط مأخوذ بالفعل!",
      },
      success: {
        fr: "Lien disponible!",
        en: "This link is available!",
        ar: "الرابط متاح!",
      },
      button: {
        fr: "Rétablir le lien",
        en: "Reset link",
        ar: "إعادة تعيين الرابط",
      },
    },
    "temporary-code-section": {
      title: {
        fr: "Protéger la fiche contact avec un mot de passe temporaire.",
        en: "Protect the contact card with a temporary password.",
        ar: "حماية بطاقة الاتصال بكلمة مرور مؤقتة."
      },
      active: {
        fr: "La protection est activée.",
        en: "Protection activated.",
        ar: "الحماية مفعلة."
      },
      inactive: {
        fr: "La protection est désactivée.",
        en: "Protection inactive.",
        ar: "الحماية غير مفعلة."
      },
      subtitle: {
        fr: "Votre code temporaire",
        en: "Your temporary code",
        ar: "كودك المؤقت"
      },
    },
  },

  appointment: {
    title: {
      fr: "Prise de rendez-vous",
      en: "Appointment booking",
      ar: "حجز المواعيد",
    },
    subtitle: {
      fr: "Offrez la possibilité à vos rencontres de réserver un créneau disponible sur votre agenda en quelques clics",
      en: "Offer your encounters the possibility to reserve an available slot on your calendar in a few clicks",
      ar: "امنح لقاءاتك إمكانية حجز فتحة متاحة في جدول أعمالك ببضع نقرات",
    },
    how: {
      fr: "Tutoriel pour obtenir votre lien ",
      en: "How to get your  link",
      ar: "دليل الحصول على رابطك",
    },
    appuyer: {
      fr: "Appuyer ici pour prendre un rendez-vous avec",
      en: "Click here to book an appointment with",
      ar: "انقر هنا لحجز موعد مع",
    },
  },

  footer: {
    title: {
      fr: " et des milliers d'autres professionnels utilisent Popcard tous les jours pour garder contact avec leurs rencontres.",
      en: " and thousands of other professionals use Popcard every day to keep in touch with the people they encounter.",
      ar: "وآلاف المهنيين الآخرين يستخدمون Popcard كل يوم للحفاظ على الاتصال مع معارفهم .",
    },
    button: {
      fr: "Obtenir ma popcard",
      en: "Get my popcard",
      ar: "احصل على بطاقتي",
    },
    reserved: {
      fr: "Tous droits réservés",
      en: "All rights reserved",
      ar: "جميع الحقوق محفوظة",
    },
  },

  productModal: {
    orderOn: {
      fr: "Commander sur:",
      en: "Order on:",
      ar: "اطلب من:",
    },
  },

  mainCard: {
    title: {
      fr: "Souhaitez-vous modifier vos informations ?",
      en: "Would you like to modify your information?",
      ar: "هل ترغب في تعديل معلوماتك؟",
    },
  },

  uploaderPic: {
    upload: {
      fr: "Prendre une photo",
      en: "Take a picture",
      ar: "التقاط صورة",
    },
    telecharger: {
      fr: "Choisissez une image existante",
      en: "Choose an existing image",
      ar: "اختر صورة موجودة",
    },
    annuler: {
      fr: "Supprimer l'image de couverture",
      en: "Delete cover image",
      ar: "حذف صورة الغلاف",
    },
    delete: {
      fr: "Supprimer l'image de profil",
      en: "Delete profile image",
      ar: "حذف صورة الملف الشخصي",
    },
  },

  changeProfilePicture: {
    takepicture: {
      fr: "Prendre une photo",
      en: "Take a picture",
      ar: "التقاط صورة",
    },
    title1: {
      fr: "Choisissez une image existante",
      en: "Choose an existing image",
      ar: "اختر صورة موجودة", // Arabic translation for title1
    },
    deleteProfilePic: {
      fr: "Supprimer l'image de profil",
      en: "Delete profile image",
      ar: "حذف صورة الملف الشخصي", // Arabic translation for deleteProfilePic
    },
    deleteCover: {
      fr: "Supprimer l'image de couverture",
      en: "Delete cover image",
      ar: "حذف صورة الغلاف", // Arabic translation for deleteCover
    },
  },

  cropImage: {
    title: {
      fr: "Recadrez votre photo pour un meilleur rendu",
      en: "Crop your photo for a better result",
      ar: "اقتصاص صورتك للحصول على نتيجة أفضل", // Arabic translation for title
    },

    button: {
      fr: "Terminer",
      en: "Finish",
      ar: "انتهى", // Arabic translation for button
    },
  },

  linkAndPdfs: {
    uploadFile: {
      en: "Upload your pdf",
      fr: "Téléchargez votre pdf",
      ar: "قم بتحميل ملف pdf الخاص بك", // Arabic translation for uploadFile
    },
  },

  onboarding: {
    auth: {
      title: {
        en: "Select your setup Language",
        fr: "Sélectionner votre langue de paramétrage",
        ar: "اختر لغة الإعدادات الخاصة بك",
      },
      // title2: {
      //   en: "You can change your display language with one click",
      //   fr: "Vous pouvez changer votre langue d'affichage en un clic",
      // },
      title2: {
        fr: "Bienvenue sur",
        en: "Welcome to",
        ar: "مرحبًا بك في",
      },
      offered: {
        fr: "offerte par",
        en: "offered by",
        ar: "مقدمة من",
      },
      subtitle2One: {
        fr: "Vous êtes l’heureux propriétaire d’une carte de visite nouvelle génération",
        en: "You are the proud owner of a new generation business card",
        ar: "أنت المالك الفخور لبطاقة عمل من الجيل الجديد",
      },
      subtitle2Two: {
        fr: "Avec Popcard, partagez vos coordonnées et informations professionnelles avec vos rencontres en un seul geste.",
        en: "With Popcard, share your contact details and professional information with your meetings in a single gesture.",
        ar: "مع Popcard، شارك تفاصيل الاتصال الخاصة بك ومعلوماتك المهنية بلمسة واحدة.",
      },
      title3: {
        fr: "Création de compte",
        en: "Create account",
        ar: "إنشاء حساب",
      },
      subtitle3: {
        fr: "Saisissez un email professionnel et un mot de passe pour vos futures connexions à votre espace popcard",
        en: "Enter a professional email and password for your future connections to your popcard space",
        ar: "أدخل بريدًا إلكترونيًا مهنيًا وكلمة مرور لعمليات تسجيل الدخول المستقبلية إلى مساحة popcard الخاصة بك",
      },
      email: {
        fr: "Email",
        en: "Email",
        ar: "البريد الإلكتروني",
      },
      password: {
        fr: "Mot de passe",
        en: "Password",
        ar: "كلمة المرور",
      },
      confirmPassword: {
        fr: "Confirmer le mot de passe",
        en: "Confirm password",
        ar: "تأكيد كلمة المرور",
      },
      minCar: {
        fr: '6 car. min',
        en: '6 char. min',
        ar: '6 أحرف كحد أدنى',
      },
      passwordNotMatching: {
        fr: "Les mots de passe ne correspondent pas",
        en: "Passwords do not match",
        ar: "كلمات المرور غير متطابقة",
      },
      button: {
        en: "Continue",
        fr: "Continuer",
        ar: "متابعة",
      },
      buttonTwo: {
        fr: "Démarrer la configuration",
        en: "Start configuration",
        ar: "بدء الإعداد",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
    },
    ConfirmEmail: {
      title: {
        fr: "Verification de mail",
        en: "Email verification",
        ar: "تأكيد البريد الإلكتروني",
      },
      subtitle: {
        fr: "Un email vient de vous être envoyée à l’adresse :",
        en: "An email has been sent to:",
        ar: "تم إرسال بريد إلكتروني إلى:",
      },
      subtitleSecond: {
        fr: "Un nouvel email vient de vous être envoyée à l'adresse :",
        en: "A new email has just been sent to:",
        ar: "تم إعادة إرسال بريد إلكتروني جديد إلى:",
      },
      subtitle2: {
        fr: "Merci de vérifier votre boîte de réception (Principale/Spam) pour confirmer votre email et poursuivre le paramétrage de votre Popcard.",
        en: "Please check your inbox (Main/Spam) to confirm your email and continue setting up your Popcard.",
        ar: "يرجى التحقق من صندوق الوارد الخاص بك (الرئيسي/البريد العشوائي) لتأكيد بريدك الإلكتروني ومتابعة إعداد Popcard الخاص بك.",
      },
      button: {
        fr: "Renvoyer le mail",
        en: "Resend email",
        ar: "إعادة إرسال البريد الإلكتروني",
      },
    },

    onboardingDetails: {
      title: {
        en: "Contact card",
        fr: "Fiche de contact",
        ar: "بطاقة الاتصال",
      },
      subtitle: {
        en: "Complete your profile information",
        fr: "Renseignez vos informations de profil",
        ar: "أكمل معلومات ملفك الشخصي",
      },
      firstName: {
        fr: "Prénom",
        en: "First name",
        ar: "الاسم الأول",
      },
      lastName: {
        fr: "Nom",
        en: "Last name",
        ar: "الاسم الأخير",
      },
      company: {
        fr: "Entreprise",
        en: "Company",
        ar: "الشركة",
      },
      position: {
        fr: "Intitulé de poste",
        en: "Position title",
        ar: "المسمى الوظيفي",
      },
      mobileNumber: {
        fr: "Numéro mobile",
        en: "Mobile number",
        ar: "رقم الجوال",
      },
      addMobileNumber: {
        fr: "Ajouter un Numéro",
        en: "Add a Phone Number",
        ar: "إضافة رقم هاتف",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
    },

    ProfessionalInfo: {
      title: {
        fr: "Coordonnées",
        en: "Contact Information",
        ar: "معلومات الاتصال",
      },
      subtitle: {
        fr: "Saisissez les coordonnées professionnelles que vous souhaitez partager avec vos rencontres",
        en: "Enter the business contact information that you want to share with your encounters",
        ar: "أدخل معلومات الاتصال التجارية التي تريد مشاركتها مع الآخرين",
      },
      email: {
        fr: "Adresse email",
        en: "Email address",
        ar: "عنوان البريد الإلكتروني",
      },
      webSite: {
        fr: "Site web",
        en: "Website",
        ar: "الموقع الإلكتروني",
      },
      adresse: {
        fr: "Adresse",
        en: "Address",
        ar: "العنوان",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
    },

    about: {
      title: {
        fr: "À propos",
        en: "About",
        ar: "حول",
      },
      subtitle: {
        fr: "Présentez-vous, parlez de vos projets et de vos passions… C’est votre première impression (digitale) sur vos rencontres !",
        en: "Introduce yourself, talk about your projects and your passions... This is your first (digital) impression on the people you encounter!",
        ar: "قدم نفسك، تحدث عن مشاريعك واهتماماتك... هذا هو الانطباع الأول (الرقمي) على الأشخاص الذين تقابلهم!",
      },
      aboutInput: {
        fr: "Bonjour, …",
        en: "Hello, …",
        ar: "مرحبًا، ...",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
    },

    socials: {
      title: {
        fr: "Réseaux sociaux",
        en: "Social media",
        ar: "وسائل التواصل الاجتماعي",
      },
      subtitle: {
        fr: "Choisissez parmi une multitude de réseaux sociaux pour mettre en valeur ce qui compte le plus pour vous",
        en: "Choose from a multitude of social networks to showcase what matters most to you",
        ar: "اختر من بين العديد من شبكات التواصل الاجتماعي لعرض ما يهمك أكثر",
      },
      button: {
        fr: "Ajouter un réseau social",
        en: "Add a social network",
        ar: "إضافة شبكة اجتماعية",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
    },

    productServices: {
      title: {
        fr: "Produits et services",
        en: "Products and services",
        ar: "المنتجات والخدمات",
      },
      subtitle: {
        fr: "Mettez en valeur vos produits et services et listez l'ensemble des plateformes sur lesquelles vos rencontres peuvent les obtenir",
        en: "Highlight your products and services and list all the platforms on which your encounters can get them",
        ar: "قدّم منتجاتك وخدماتك وقم بإدراج جميع المنصات التي يمكن للأشخاص الذين تقابلهم الحصول عليها",
      },
      addProductButton: {
        fr: "Ajouter un nouveau produit/service",
        en: "Add a new product/service",
        ar: "إضافة منتج / خدمة جديدة",
      },
      addProductModal: {
        titleAdd: {
          fr: "Ajouter un produit/service",
          en: "Add a product/service",
          ar: "إضافة منتج / خدمة",
        },
        titleEdit: {
          fr: "Modifier un produit/service",
          en: "Edit a product/service",
          ar: "تعديل منتج / خدمة",
        },
        name: {
          fr: "Nom du produit/service",
          en: "Product/service name",
          ar: "اسم المنتج / الخدمة",
        },
        prix: {
          fr: "Prix",
          en: "Price",
          ar: "السعر",
        },
        image: {
          fr: "Photos (3 max.)",
          en: "Photos (3 max.)",
          ar: "الصور (حد أقصى 3)",
        },
        addImageButton: {
          fr: "Uploader une image",
          en: "Upload a picture",
          ar: "رفع صورة",
        },
        link: {
          fr: "Lien de vente",
          en: "Sales link",
          ar: "رابط المبيعات",
        },
        button: {
          fr: "Ajouter un nouveau lien de vente",
          en: "Add a new sales link",
          ar: "إضافة رابط مبيعات جديد",
        },
        buttonSave: {
          fr: "Enregistrer",
          en: "Save",
          ar: "حفظ",
        },
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
    },

    calendly: {
      title: {
        fr: "Prise de rendez-vous",
        en: "Appointment booking",
        ar: "حجز المواعيد",
      },
      subtitle: {
        fr: "Offrez la possibilité à vos rencontres de réserver un créneau disponible sur votre agenda en quelques clics",
        en: "Offer your encounters the possibility to reserve an available slot on your calendar in a few clicks",
        ar: "قدم للأشخاص الذين تقابلهم الفرصة لحجز موعد في فترة زمنية شاغرة في جدولك بضغطات قليلة فقط.",
      },
      tutoriel: {
        fr: "Tutoriel pour obtenir votre lien ",
        en: "How to get your link",
        ar: "كيفية الحصول على رابطك",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
    },

    links: {
      title: {
        fr: "Liens et pdfs",
        en: "Links and pdfs",
        ar: "الروابط والملفات",
      },
      subtitle: {
        fr: "Saisissez un lien vers un site ou un document hébergé pour permettre à vos rencontres d’y accéder en un clic",
        en: "Enter a link to a site or hosted document to allow your meetings to access it with one click",
        ars: "أدخل رابطًا إلى موقع أو مستند مستضاف للسماح لاجتماعاتك بالوصول إليه بنقرة واحدة",
        ar: "أدخل رابطًا إلى موقع أو قم بتحميل ملف PDF لمشاركته مع معارفك بنقرة واحدة.",
      },
      pdfTitle: {
        fr: "PDF",
        en: "PDF",
        ar: "ملف PDF",
      },
      linkTitle: {
        fr: "LIEN",
        en: "LINK",
        ar: "رابط",
      },
      imgPreview: {
        fr: "Télécharger un aperçu",
        en: "Upload a preview",
        ar: "تحميل معاينة",
      },
      urlInput: {
        fr: "url",
        en: "URL",
        ar: "الرابط",
      },
      titleInput: {
        fr: "titre",
        en: "Title",
        ar: "العنوان",
      },
      descriptionInput: {
        fr: "Description",
        en: "Description",
        ar: "الوصف",
      },
      addLink: {
        fr: "Ajouter un lien",
        en: "Add a link",
        ar: "إضافة رابط",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
    },

    images: {
      title: {
        fr: "Images",
        en: "Images",
        ar: "الصور",
      },
      subtitle: {
        fr: "Exprimez votre personnalité et démontrez votre savoir-faire à travers cette galerie d’images",
        en: "Express your personality and demonstrate your expertise through this gallery of images",
        ar: "عبّر عن شخصيتك وأظهر خبرتك من خلال معرض الصور",
      },
      button: {
        fr: "Uploader une image",
        en: "Upload a picture",
        ar: "رفع صورة",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
    },

    videos: {
      title: {
        fr: "Vidéos",
        en: "Videos",
        ar: "مقاطع الفيديو",
      },
      title2: {
        fr: "Vous pouvez changer votre langue d'affichage en un clic",
        en: "You can change your display language with one click",
        ar: "يمكنك تغيير لغة العرض بنقرة واحدة",
      },
      subtitle: {
        fr: "Saisissez un lien vers les vidéos que vous souhaitez mettre en valeur auprès de vos rencontres",
        en: "Enter a link to the videos you want to share with your encounters",
        ar: "أدخل رابط الفيديوهات التي ترغب في مشاركتها مع الأشخاص الذين تلتقي بهم",
      },
      urlInput: {
        fr: "url",
        en: "URL",
        ar: "الرابط",
      },
      descriptionInput: {
        fr: "Description",
        en: "Description",
        ar: "الوصف",
      },
      button: {
        fr: "Ajouter un vidéo",
        en: "Add a video",
        ar: "إضافة فيديو",
      },
      buttonNext: {
        fr: "Suivant",
        en: "Next",
        ar: "التالي",
      },
      buttonSave: {
        fr: "Enregistrer",
        en: "Save",
        ar: "حفظ",
      },
      buttonFinish: {
        fr: "Terminer",
        en: "Finish",
        ar: "إنهاء",
      },
    },






  },


};
