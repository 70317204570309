const useWindowOpen = (url, social_id = null) => {
  if (social_id === "637235ad60136a9a3e26f784") {
    url = `https://wa.me/+${url.replace("-", "")}`;
  } else if (!/^https?:\/\//i.test(url)) {
    url = "http://" + url;
  }

  return window.open(url, "_blank");
};

export default useWindowOpen;
