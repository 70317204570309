import React, { useEffect, useState } from "react";
import Loading from "../../componants/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Auth from "../../componants/onBoarding/Auth";
import ConfirmEmail from "../../componants/onBoarding/ConfirmEmail";
import Details from "../../componants/onBoarding/Details";
import ProfessionalInfo from "../../componants/onBoarding/ProfessionalInfo";
import About from "../../componants/onBoarding/About";
import Socials from "../../componants/onBoarding/Socials";
import ProductServices from "../../componants/onBoarding/ProductServices";
import Links from "../../componants/onBoarding/Links";
import Images from "../../componants/onBoarding/Images";
import Videos from "../../componants/onBoarding/Videos";
import Calendly from "../../componants/onBoarding/Calendly";

import "./Onboarding.css";

import {
  getCollaborator,
} from "../../redux/appSlices/collaborator/collaboratorSlice";

import { onboardingTranslation } from "../../translation";
import {
  useGetCollaboratorQuery,
  usePatchCollaboratorMutation,
} from "../../redux/appSlices/collaborator/collaboratorApiSlice";
import useLoggedStatus from "../../hooks/useLoggedStatus";

const Onboarding = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const logged = useLoggedStatus();

  const { data, isLoading } = useGetCollaboratorQuery(id);
  const [patchCollaborator] = usePatchCollaboratorMutation();

  const [onboardingStep, setOnboardingStep] = useState(null);
  const [finalStep, setFinalStep] = useState(false)
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState(
    <Loading />
  );

  const lang =
    useSelector((state) => state.collaborator.collaboratorInfo.language) ??
    "fr";

  useEffect(() => {
    if (data) {
      if(data.data.collaborator.onboarding_step == 10 && !finalStep) {
        navigate(`/${data.data.collaborator.collaborator_id}`);
      }
      dispatch(getCollaborator(data.data.collaborator));
      setOnboardingStep(data.data.collaborator.onboarding_step);
    }
  }, [data]);

  const handlePreviousStep = () => {
    patchCollaborator({
      id: data?.data?.collaborator?._id,
      content: {
        onboarding_step: onboardingStep - 1,
      },
    });
  };
  const handleNextStep = () => {
    patchCollaborator({
      id: data?.data?.collaborator?._id,
      content: {
        onboarding_step: onboardingStep + 1,
      },
    });
  };

  const steps = [
    <Auth />,
    <ConfirmEmail />,
    <Details />,
    <ProfessionalInfo />,
    <About />,
    <Socials />,
    <ProductServices />,
    <Calendly />,
    <Links />,
    <Images />,
    <Videos finalStep={finalStep} setFinalStep={setFinalStep}/>,
  ];

  const finalSection = <Videos finalStep={true} />;

  useEffect(() => {
    if (onboardingStep === 10 && finalStep) {
      setCurrentOnboardingStep(<Videos finalStep={true} />);
    } else if (onboardingStep >= -1 && onboardingStep != null) {
      setCurrentOnboardingStep(steps[onboardingStep + 1]);
    }
  }, [onboardingStep]);

  if (!data && !isLoading) {
    window.location.href = 'https://www.getpopcard.com/'
  }

  // if (isLoading) return <Loading />;
  if (onboardingStep >= -1 && onboardingStep != null)
    return (
      <div className={lang === 'ar' ? 'font-readexRegular' : 'font-SteradianRegular'}>
        {/* the header of onboarding sections */}
        {onboardingStep >= 0 && logged && onboardingStep !== 1 && (
          <div className="flex justify-between pt-[35px] mx-[26px]" dir="ltr">
            <div className="cursor-pointer" onClick={handlePreviousStep}>
              <img src="../../assets/img/icons/arrow-back.svg" alt='back' />
            </div>
            {onboardingStep >= 1 && onboardingStep !== 10 && (
              <div className={`cursor-pointer ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`} onClick={handleNextStep}>
                {onboardingTranslation?.buttonPass?.[lang]}
              </div>
            )}
          </div>
        )}
        {/* END the header of onboarding sections */}
        {onboardingStep >= 0 ? (
          logged ? (
            currentOnboardingStep
          ) : (
            navigate("/login")
          )
        ) : (
          currentOnboardingStep
        )}
      </div>
    );
  return <Loading />;
};

export default Onboarding;
