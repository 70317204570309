
export default function PhonePicker({ onChange }) {
  return (
    <div dir='ltr' className="relative flex w-full items-center gap-[5px] mt-[20px]">
      <div className="prefix">
        <input
          id="phone-pickerr"
          className="border-b border-b-[#ccb7b7] w-[80px] px-[20px] py-[10px] outline-none"
          defaultValue="+212"
          onChange={onChange}
          name="prefix"
        />
      </div>

      <div className="w-full">
        <input
          id="phone-pickerr"
          className='w-full px-[20px] py-[10px] border-b border-b-[#ccb7b7] outline-none'
          onChange={onChange}
          name='phone'
        />
      </div>
    </div>
  );
}
