import { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineContentCopy } from "react-icons/md";
import { v4 } from "uuid";

export default function CopyToClipboard({ value, color }) {
    const [copied, setCopied] = useState(false);
    async function handleClick() {
        _copy(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 700);
    }
    return (
        <span
            onClick={() => handleClick()}
            style={{
                display: "inline-block",
                flexShrink: 0,
                cursor: "pointer",
            }}
            title="Copier dans le presse-papier"
        >
            {copied ? (
                <FaCheck size={22} className="mt-[2px] ml-[-2px]"
                    style={{ color: color }}
                />
            ) : (
                <MdOutlineContentCopy size={22} className="mt-[2px] ml-[-2px]"
                    style={{ color: color }}
                />
            )}
        </span>
    );
}

function _copy(text) {
    const id = "_copy_input" + v4();
    let input = document.createElement("input");
    input.value = text;
    input.id = id;
    input.style.position = "absolute";
    input.style.top = "-100px";
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    document.querySelector(`#${id}`)?.remove();
}
