import React from "react";
import "../../styles/Switch.scss";

const Switch = ({ value, checked, name, onChange }) => {
  return (
    <div className="switch-container">
      <input
        type="checkbox"
        className="switch-input"
        id={`switch-${value}`}
        name={name}
        checked={checked}
      />
      <label onClick={onChange} htmlFor={`switch-${value}`} className="switch-label">
        <span className="switch-circle"></span>
      </label>
    </div>
  );
};

export default Switch;
