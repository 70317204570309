import React from 'react'
import { translation } from '../../translation'
import useCollaboratorRestriction from '../../hooks/useCollaboratorRestriction';

function ListImage({ image, lang, handleDelete }) {

  const restriction = useCollaboratorRestriction();

  return (
    <div className='relative h-[100px] w-[100px] rounded-[10px]'>
      {!restriction.restrict_images ?
        <div
          className='text-white bg-labels absolute bottom-0 w-full py-[7px] text-center rounded-b-[10px] cursor-pointer'
          onClick={() => { handleDelete(`${image.image}`) }}
        >
          {translation?.loginPage?.delete?.[lang]}
        </div>
        : <div className='absolute bottom-[10px] right-[10px]'><img src='../../assets/img/icons/lock.svg' alt='' className='w-[15px] h-[15px]' /></div>
      }
      <img className='object-cover h-[100px] w-[100px] rounded-[10px]' src={process.env.REACT_APP_IMAGE_PATH + image.image} alt={image.title} loading='lazy' />

    </div>
  )
}

export default ListImage