import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

const Phone = ({ phoneData, onDelete, handlePhoneChange, index }) => {
  return (
    <div dir='ltr' className="flex gap-5 mt-3 items-center">
      <input
        className="text-lg text-black bg-popcard border-b border-inputBottom h-9 w-12 focus:outline-none"
        type="number"
        onChange={(e) => handlePhoneChange(e, index)}
        name="prefix"
        maxLength={10}
        defaultValue={phoneData.prefix}
        placeholder={212}
      />

      <input
        className="text-lg text-black bg-popcard border-b border-inputBottom h-9 focus:outline-none"
        type="phone"
        name="number"
        onChange={(e) => handlePhoneChange(e, index)}
        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
        defaultValue={phoneData.number}
        placeholder="6 01 02 03 04"
        maxLength={12}
      />

      <div>
        <img src="../../assets/img/icons/delete.svg" alt="delete Phone" onClick={() => onDelete(phoneData._id)} />
      </div>
    </div>
  );
};

export default Phone;
