import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: {},
    reducers: {
        setCredentials: (state, action) => {
            const { status, data } = action.payload
            state.status = status
            state.token = data.token
            state.collaborator_id = data.collaborator_id
            state.onboarding_step = data.onboarding_step
            localStorage.setItem("collaborator_id", data.collaborator_id);
            localStorage.setItem("token", data.token);
            localStorage.setItem("refreshToken", data.refreshToken)
            localStorage.setItem("user_token", data.user_token)
            localStorage.setItem("qrcode-offline",JSON.stringify(data.qrCode));
        },
        logOut: (state, action) => {
            state.token = null
        }
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth?.token
export const selectCurrentCollaboratorId = (state) => state.auth.collaborator_id