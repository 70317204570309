import React from 'react'

const PdfReader = ({pdfPath}) => {
  return (
    <iframe
        title="PDF Viewer"
        src={`${process.env.REACT_APP_PUBLIC_API}/pdfs/${pdfPath}`}
        width="90%"
        height="90%"
        className='py-[30px] min-h-[90vh] w-[90%] mx-auto'
      >
        <p>Your browser does not support embedded PDF files. You can <a href={`${process.env.REACT_APP_PUBLIC_API}/pdfs/${pdfPath}`}>download the PDF</a> instead.</p>
      </iframe>
  )
}

export default PdfReader