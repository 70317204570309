import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { setOnboardingStep } from "../../redux/appSlices/collaborator/collaboratorSlice";
import AddProductModal from "../UI/AddProductModal";
import ListProduct from "../UI/ListProduct";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import { translation } from "../../translation";
import { toast } from "react-toastify";

const ProductServices = ({ edit, setOpenEditPopup }) => {
  // const productTranslation = useSelector(
  //   (state) => state.translation.ProductServices
  // );
  const productTranslation = translation.onboarding.productServices
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();

  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

  const [openProductPopup, setOpenProductPopup] = useState(false);
  const [listProduct, setListProduct] = useState(collaborator.products);
  const [productIdToEdit, setProductIdToEdit] = useState(null);
  const [currentProductEdit, setCurrentProductEdit] = useState();

  useEffect(() => {
    if (restriction.restrict_products) {
      setListProduct(restriction.products);
    }
  }, [restriction]);

  // ############### DEV AREA
  // ######################
  // ########################################################

  const [loading, setLoading] = useState();
  const [safe, setSafe] = useState(true);

  const handleChange = () => { };
  const handleSubmit = () => {
      patchCollaborator({
        id: collaborator._id,
        content: {
          products: listProduct,
          ...(edit ? {} : { onboarding_step: 6 }),
        },
      }).unwrap().then().catch((err) => {
        toast.error(edit ? translation?.updateErrorMessage?.[lang] : translation?.saveErrorMessage?.[lang])
      })
  };

  // ########################################################
  // ######################
  // ############### END DEV AREA


  return (
    <section className={`pt-[7vh] mx-5 pb-[120px] ${lang === 'ar' ? 'font-readexRegular' : 'font-SteradianRegular'}`}>
      <div>
        <div className={`text-2xl ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
          {productTranslation.title[lang]}
        </div>
        <div className="mt-5 text-lg">{productTranslation.subtitle[lang]}</div>

        {listProduct.length > 0 && (
          <div className="mt-16 flex flex-col gap-10">
            {!restriction.restrict_products
              ? listProduct.map((product) => (
                <ListProduct
                  product={product}
                  lang={lang}
                  setProductIdToEdit={setProductIdToEdit}
                  setOpenProductPopup={setOpenProductPopup}
                  listProduct={listProduct}
                  setListProduct={setListProduct}
                />
                // <ListProduct key={product._id ? product._id : null} product={product} lang={lang} />
              ))
              : listProduct.map((product) => (
                <div className="relative flex gap-3 mt">
                  <div className="flex-shrink-0 h-[114px] w-[114px]">
                    <img
                      className="object-cover w-full h-full rounded-lg"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${product?.pictures?.[0]}`}
                    />
                  </div>
                  <div className="flex flex-col justify-between w-full">
                    <div className="text-[#101010] font-[400] text-[16px] mr-[18px]">
                      {product?.title?.[lang]}
                    </div>
                    <div className="text-[#4E3131] font-bold text-[18px]">
                      {product?.price?.amount === 0 || !product?.price?.amount
                        ? ""
                        : product?.price?.amount}{" "}
                      <span className="text-[16px] font-normal">
                        {product?.price?.amount === 0 ||
                          !product?.price?.amount
                          ? ""
                          : product?.price?.currency}
                      </span>
                    </div>
                    {/* ########################## List of store ########################## */}
                    <div className="flex gap-[5px]">
                      {product?.links?.map((link) => (
                        <div>
                          <a link={
                            // link?.url
                            link.store.label === 'Whatsapp'
                              ? `https://wa.me/+${link.url.replace("-", "")}`
                              : (link.url && link.url.startsWith('http') ? link.url : `http://${link?.url}`)
                          } target="_blank">
                            <img
                              src={`${link?.store?.icon}.png`}
                              className="w-[35px] h-[35px] shadow-sm object-contain rounded-lg"
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="absolute right-0 ml-auto !w-[15px]">
                    <img
                      src="../../assets/img/icons/lock.svg"
                      className="w-full h-[15px]"
                    />
                  </div>
                </div>
              ))}
          </div>
        )}

        {!restriction.restrict_products && (
          <div
            className="flex items-center gap-2 text-labels text-lg mt-8 cursor-pointer"
            onClick={() => setOpenProductPopup(true)}
          >
            <AiOutlinePlusCircle />
            {productTranslation.addProductButton[lang]}
          </div>
        )}
      </div>
      <div className="button-container">
        <button
          className={`${safe ? "btn-fields btn-active" : "btn-fields"} ${lang === 'ar' ? '!font-readexRegular' : ''}`}
          id="btn-next"
          onClick={
            edit
              ? (e) => {
                handleSubmit(e);
                setOpenEditPopup(false);
              }
              : handleSubmit
          }
          disabled={!safe}
        >
          {edit ? (
            <img
              src="/assets/img/loaders/loading.gif"
              style={{
                display: loading ? undefined : "none",
                padding: "2px",
              }}
              width="23px"
              alt=""
              className="ml-[45%]"
            />
          ) : null}
          {loading
            ? null
            : edit
              ? productTranslation?.buttonSave[lang]
              : productTranslation?.buttonNext[lang]}
        </button>
      </div>

      {openProductPopup && (
        <AddProductModal
          close={setOpenProductPopup}
          modalTranslation={productTranslation?.addProductModal}
          lang={lang}
          listProduct={listProduct}
          setListProduct={setListProduct}
          productIdToEdit={productIdToEdit}
          setProductIdToEdit={setProductIdToEdit}
        />
      )}
    </section>
  );
};

export default ProductServices;
