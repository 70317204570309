import axios from "axios";
import useUserToken from "./useUserToken";
import { useTrackingMutation } from "../redux/appSlices/collaborator/collaboratorApiSlice";

// const SendTracking = ({ collaborator_id, action, url=undefined, componant=undefined, title=undefined, social_id=undefined }) => {
const useTracking = () => {
  const userToken = useUserToken();
  const [tracking] = useTrackingMutation();

  const sendTracking = ({
    action,
    collaborator_id,
    url = undefined,
    componant = undefined,
    title = undefined,
    social_id = undefined,
  }) => {
    const localCollabId = localStorage.getItem("collaborator_id");
    if (collaborator_id !== localCollabId) {
      axios
        .get(
          "https://api.ipdata.co/?api-key=54d73da804f5aad094403d13477111312532c025ae89c1d44ed34898",
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          tracking({
            collaborator_id: collaborator_id,
            user_token: userToken.getUserToken(),
            action: action,
            ...response.data,
            user_agent: navigator.userAgent,
            clicked_on: {
              componant: componant,
              url: url,
              title: title,
              social_id: social_id,
            },
          }).then((res) => {
            if (action === 51) {
              localStorage.setItem("tracking", res?.data?.data.tracking._id);
            }
          });
        });
    }
  };

  return {
    sendTracking,
  };
};

export default useTracking;
