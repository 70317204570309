import * as yep from "yup";

const msg = {
  requiredPhone: "Numéro de téléphone est requis",
  invalidPhone: "Numéro de téléphone est requis",
};

export const completerPhoneSchema = yep.object().shape({
  phone: yep.string()
    .matches(/^[+]\d{1,3}-\d+$/, msg.invalidPhone)
    .required(msg.requiredPhone),
});

export const validateCompleterForm = (values) => {
  let errors = {};

  if (!values.phone.split('-')[1]) {
    errors.phone = msg.requiredPhone;
  } else if (!isValidPhone(values.phone.split('-')[1])) {
    errors.phone = "Numéro de téléphone invalide";
  }

  return errors;
};

const isValidPhone = (phone) => {
  return true;
};
