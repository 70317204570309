import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "./popcard-black.png";
import { useSelector } from "react-redux";
import { usePatchCollaboratorMutation } from "../../../redux/appSlices/collaborator/collaboratorApiSlice";
import { QRCode } from "react-qrcode-logo";
import { useParams, useNavigate } from "react-router-dom";

const simpleVcard = ({ first_name, last_name, email, phone }) => {
  const phoneNumbers = phone
    .map((p) => `TEL:+${p.prefix}-${p.number}`)
    .join("\n");
  return `BEGIN:VCARD
VERSION:3.0
FN:${first_name} ${last_name}
N:${first_name};${last_name}
EMAIL:${email}
${phoneNumbers}
END:VCARD
`;
};

function getCurrentDateTime(currentDate) {
  var formattedDateTime =
    currentDate.getDate().toString().padStart(2, "0") +
    "-" +
    (currentDate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    currentDate.getFullYear() +
    " " +
    currentDate.getHours().toString().padStart(2, "0") +
    ":" +
    currentDate.getMinutes().toString().padStart(2, "0");
  return formattedDateTime;
}

const updateQrcodeBtn = {
  en: "Update My Qrcode",
  fr: "Mettre à jour mon code QR",
};

const lastUpdateText = {
  en: "last updated on :",
  fr: "Dernière mise à jour le :",
};

const SettingQrCode = ({ setStep3, insideSettings = true, lang = "fr" }) => {
  const [offline, setOffline] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [logoSize, setLogoSize] = useState(400);
  const [patchCollaborator] = usePatchCollaboratorMutation();
  const [qrCode, setQrCode] = useState({
    offline: "",
    online: "",
  });

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const navigate = useNavigate();

  useEffect(() => {
    let id;
    if (
      !localStorage.getItem("collaborator") ||
      localStorage.getItem("collaborator") === "undefined" ||
      localStorage.getItem("collaborator") === "null"
    ) {
      localStorage.clear();
      return navigate("/login");
    }
    try {
      setIsLoading(true);
      if (offline) {
        id = setTimeout(() => {
          setQrCode((prev) => ({
            ...prev,
            offline: simpleVcard(
              JSON.parse(localStorage.getItem("collaborator"))
            ),
          }));
          setIsLoading(false);
        }, [300]);
      } else {
        id = setTimeout(() => {
          setQrCode((prev) => ({
            ...prev,
            online:
              "https://app.getpopcard.com/" +
              localStorage.getItem("collaborator_id"),
          }));
          setIsLoading(false);
        }, [300]);
      }
    } catch (error) {
      localStorage.clear();
      return navigate("/login");
    }
  }, [offline]);

  useEffect(() => {
    if (window.innerWidth + 20 < logoSize) {
      setLogoSize(window.innerWidth - 40);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < logoSize) {
        setLogoSize(window.innerWidth - 40);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {
        setLogoSize(window.innerWidth - 40);
      });
    };
  }, []);
  // update pwa offline
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lastQrCodeUpdate, setLastQrCodeUpdate] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("lastQrCodeUpdate")) {
      localStorage.setItem("lastQrCodeUpdate", getCurrentDateTime(new Date()));
    }
    setLastQrCodeUpdate(
      localStorage.getItem("lastQrCodeUpdate") || getCurrentDateTime(new Date())
    );
  }, []);
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [navigator.onLine]);

  const updateQrCode = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL +
          "/collaborators/" +
          localStorage.getItem("collaborator_id")
      );
      localStorage.setItem("lastQrCodeUpdate", getCurrentDateTime(new Date()));
      setLastQrCodeUpdate(getCurrentDateTime(new Date()));
      const data = response.data.data.collaborator;
      localStorage.setItem("collaborator", JSON.stringify(data));
      console.log("data a sat", data);
    } catch (error) {
      console.log("erro", error);
    }
  };
  return (
    <section className="max-w-[500px] mx-auto">
      {/* <div className="fixed w-screen h-16  top-0 left-0 z-10 bg-[#FFFAF4]"></div> */}
      {insideSettings && (
        <img
          src="../../assets/img/icons/arrow-back.svg"
          alt=""
          srcSet=""
          onClick={() => {
            setStep3(false);
          }}
          className="cursor-pointer absolute top-0 mt-[40px] ml-[-10px]"
        />
      )}

      <div className="flex items-center flex-col w-full md:w-[80%] md:mx-auto gap-8">
        <div className="flex justify-center">
          <img src={logo} alt="logo" className="h-[2rem] mt-8" />
        </div>
        <div className="w-full flex gap-8 justify-between">
          <button
            onClick={(e) => {
              setOffline(true);
            }}
            className={`grow-1 flex justify-center items-center py-2 px-4 rounded-lg grow border-2 duration-300 ease-in-out  ${
              !offline
                ? "bg-transparent text-black border-black"
                : "bg-black text-white"
            } ${!offline ? "hover:bg-black hover:text-white" : ""}`}
          >
            Offline QRC
          </button>
          <button
            onClick={(e) => {
              setOffline(false);
            }}
            className={`grow-1 flex justify-center items-center py-2 px-4 rounded-lg grow border-2 duration-300 ease-in-out ${
              offline
                ? "bg-transparent text-black border-black"
                : "bg-black text-white"
            } ${
              offline
                ? "hover:bg-black hover:text-white"
                : "hover:bg-transparent hover:text-black hover:border-black"
            }`}
          >
            Online QRC
          </button>
        </div>
        <div className=" aspect-square rounded-md mt-8 w-full flex items-center justify-center bg-transparent">
          {isLoading && <div className="loader"></div>}

          {!isLoading && (
            <QRCode
              size={logoSize}
              value={qrCode[offline ? "offline" : "online"]}
              bgColor="#FFFAF4"
              eyeColor={[
                "#000",
                "#000",
                "#000", // bottom/left eye
              ]}
              eyeRadius={[
                {
                  // top/left eye
                  outer: [30, 30, 0, 30],
                  inner: [150, 150, 150, 150],
                },
                {
                  // top/left eye
                  outer: [30, 30, 30, 0],
                  inner: [150, 150, 150, 150],
                },
                {
                  // top/left eye
                  outer: [30, 0, 30, 30],
                  inner: [150, 150, 150, 150],
                },
              ]}
              qrStyle={"dots"}
            />
          )}
        </div>
        {isOnline && offline && (
          <>
            <button
              onClick={(e) => {
                updateQrCode();
              }}
              className={`grow-1 flex justify-center items-center py-2 px-4 -mt-2 rounded-lg grow border-2 duration-300 ease-in-out bg-black text-white`}
            >
              {updateQrcodeBtn[lang]}
            </button>
            <span className="text-center -mt-4">
              {lastUpdateText[lang]} {lastQrCodeUpdate}
            </span>
          </>
        )}
      </div>
    </section>
  );
};

export default SettingQrCode;
