import React, { useState } from "react";
import { useSelector } from "react-redux";
import useLoggedStatus from "../../../hooks/useLoggedStatus";
import EditButton from "../EditButton";
import EditComponants from "../EditComponants";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  AiOutlineClose,
  AiFillCaretLeft,
  AiFillCaretRight,
} from "react-icons/ai";
import { IoClose } from "react-icons/io5";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Mousewheel, Keyboard, Pagination } from "swiper/modules";
import useCollaboratorRestriction from "../../../hooks/useCollaboratorRestriction";
import { translation } from "../../../translation";
import EmptyContent from "../EmptyContent";
import useTracking from "../../../hooks/useTracking";
// import { Pagination, Navigation } from "swiper/modules";

const TestShowImages = () => {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const initialEditId = 7;
  const [previewImage, setPreviewImage] = useState(false);
  const [currentImage, setCurrentImage] = useState(-1);

  const logged = useLoggedStatus();
  const restriction = useCollaboratorRestriction();
  const { sendTracking } = useTracking();

  let images = collaborator?.images;
  if (restriction?.restrict_images) {
    images = restriction.images;
  }

  if (!logged && !images?.length > 0) {
    return null;
  }

  const handleTracking = (url, title) => {
    sendTracking({
      action: 110,
      collaborator_id: collaborator?.collaborator_id,
      componant: "Images",
      url: url,
      title: title,
    });
  };

  return (
    <>
      {restriction.show_images && (
        <section className="mt-9">
          <div className="flex justify-between items-center mb-[3vh]">
            <h2
              className={`text-xl ${
                lang === "ar" ? "font-lalezarRegular" : "font-SteradianMedium"
              }`}
            >
              {translation?.sectionTitle?.images?.[lang]}
            </h2>
            {logged && (
              <EditButton
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
              />
            )}
          </div>
          {logged &&
            !restriction?.restrict_images &&
            collaborator.images?.length === 0 && (
              <EmptyContent
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
                sectionTitle={translation?.sectionTitle?.images?.[lang]}
              />
            )}
          <div className="mt-3">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={10}
              Keyboard={true}
              mousewheel={true}
              cssMode={true}
              pagination={{
                enabled: true,
                clickable: true,
                type: "fraction",
              }}
              modules={[Mousewheel, Pagination, Keyboard]}
              className="mySwiper"
            >
              {images?.map((image, index) => (
                <SwiperSlide key={image._id} className="!w-fit">
                  <div
                    className="h-[150px] sm:w-[175px] w-[150px] sm:h-[175px] bg-white shadow-md bg-cover bg-center rounded-xl object-cover"
                    // key={image._id}
                    style={{
                      backgroundImage:
                        "url(" +
                        `${process.env.REACT_APP_IMAGE_PATH}${image.image}` +
                        ")",
                    }}
                    onClick={() => {
                      setPreviewImage(true);
                      setCurrentImage(index);
                      // console.log("open Image", previewImage);
                      handleTracking(image?.image, image?.title);
                    }}
                  >
                    {/* <img
                  className="object-cover h-[13.5rem] w-[13.5rem] rounded-xl"
                  src={`${process.env.REACT_APP_IMAGE_PATH}${image.image}`}
                /> */}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {logged && openEditPopup && (
            <EditComponants
              componantId={componantToEditId}
              setOpenEditPopup={setOpenEditPopup}
            />
          )}

          {previewImage && (
            <div
              className={`flex flex-col justify-center fixed top-0 w-full h-[100vh] bg-black bg-opacity-40 z-[9999999] max-w-[580px] ${
                lang == "ar" ? "mr-[-20px] ml-5" : "ml-[-20px] mr-5"
              }`}
            >
              <div className="flex flex-col gap-[10px]">
                {/* <IoClose
              size={20}
              className="text-white ml-auto font-bold mr-[10px]"
              onClick={() => {
                setPreviewImage(false);
              }}
            /> */}
                <div
                  className="text-white ml-auto text-[25px] font-bold mr-[10px] cursor-pointer"
                  onClick={() => {
                    setPreviewImage(false);
                  }}
                >
                  x
                </div>
                <div className="relative">
                  <img
                    className="w-full max-h-[40vh] object-contain"
                    src={`${process.env.REACT_APP_IMAGE_PATH}${images[currentImage]?.image}`}
                  />
                  <div className="w-full flex justify-between absolute top-[50%]">
                    <div
                      className={`relative ${lang == "ar" ? "rotate-180" : ""}`}
                      onClick={() => {
                        if (currentImage > 0) {
                          setCurrentImage(currentImage - 1);
                          handleTracking(
                            images[currentImage - 1]?.image,
                            images[currentImage - 1]?.title
                          );
                        }
                      }}
                    >
                      <div className="relative right-0 border-transparent border-[26px] border-r-[#3f3f3f]" />
                      <div className="absolute right-[2px] top-[6px] border-transparent border-[20px] border-r-white cursor-pointer" />
                    </div>

                    <div
                      className={`relative ${lang == "ar" ? "rotate-180" : ""}`}
                      onClick={() => {
                        if (currentImage < images.length - 1) {
                          setCurrentImage(currentImage + 1);
                          handleTracking(
                            images[currentImage + 1]?.image,
                            images[currentImage + 1]?.title
                          );
                        }
                      }}
                    >
                      <div className="relative border-transparent border-[26px] border-l-[#3f3f3f]" />
                      <div className="absolute left-[2px] top-[6px] border-transparent border-[20px] border-l-white  cursor-pointer" />
                    </div>
                  </div>
                  <div className="text-[#6B7280] text-[18px] text-center">
                    <span className="text-black text-[20px]">
                      {currentImage + 1}
                    </span>
                    /{images.length}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default TestShowImages;
