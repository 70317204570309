


import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetCollaboratorQuery, usePatchCollaboratorMutation } from "../../../redux/appSlices/collaborator/collaboratorApiSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const DefaultLanguageSelector = () => {
    const { id } = useParams();
    const { data, isLoading } = useGetCollaboratorQuery(id);

    const [patchCollaborator] = usePatchCollaboratorMutation();


    const options = [
        { value: "fr", label: "Francais", icon: "/assets/img/icons/fr-flag.svg" },
        { value: "en", label: "English", icon: "/assets/img/icons/en-flag.svg" },
        { value: "ar", label: "Arabic", icon: "/assets/img/icons/ar-flag.png" },
    ];

    const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);

    let collabLang = useSelector((state) => state.collaborator.collaboratorInfo.language)
    const [lang, setLang] = useState(collabLang)

    useEffect(() => {
        setLang(data.data.collaborator.language)
    }, [data])

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (lang) => {
        patchCollaborator({
            id: collaborator._id,
            content: {
                language: lang
            }
        }).unwrap().then((res) => {
            const successMessage = {
                ar: 'تم تحديث لغة ملفك الشخصي بنجاح',
                fr: 'Votre langue par défaut a été mise à jour avec succès',
                en: 'Your default language has been updated successfully'
            };
            toast(`✅ ${successMessage[lang]}`)
        }).catch((err) => {
            const errorMessage = {
                ar: 'حدث خطأ أثناء تحديث لغة ملفك الشخصي',
                fr: 'Une erreur s\'est produite lors de la mise à jour de votre langue par défaut',
                en: 'An error occurred while updating your default language'
            };
            toast.error(`❌ ${errorMessage[lang]}`)
        })
        setIsDropdownOpen(false);
    }



    return (
        <div className="dropdown-container">
            <div className="custom-dropdown">
                <div
                    className="custom-dropdown__selected-item"
                    onClick={handleDropdownToggle}
                    style={{ backgroundColor: "#FFFAF5" }}
                >
                    <img
                        src={options.find(option => option.value === lang).icon}
                        alt={`${options.find(option => option.value === lang).label} Flag`}
                        className="custom-dropdown__flag"
                    />
                    <span className="custom-dropdown__language">{options.find(option => option.value === lang).label}</span>
                    <span className="custom-dropdown__arrow text-primaryP text-[13px]">
                        {isDropdownOpen ? "▲" : "▼"}
                    </span>
                </div>
                {isDropdownOpen && (
                    <div
                        className="custom-dropdown__dropdown"
                        style={{ backgroundColor: "#FFF3E7" }}
                    >
                        {options
                            .filter((option) => option.value !== lang)
                            .map((opt, i) => (
                                <div
                                    className={`flex items-center p-[8px] cursor-pointer text-[20px] pt-[18px] ${i === 0 ? 'mt-[40px]' : 'rounded-b-[18px]'} hover:bg-[#FFDBB3]`}
                                    onClick={() => { handleOptionClick(opt.value) }}
                                >
                                    <img
                                        src={opt.icon}
                                        alt="French Flag"
                                        className="h-8 w-8 rounded-[50%] mr-2 bg-cover"
                                    />
                                    <span className="flex-grow">{opt.label}</span>
                                </div>
                            ))
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default DefaultLanguageSelector;
