import React, { useState } from "react";
import { useSelector } from "react-redux";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import { translation } from "../../translation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { languages } from "../../constants";

const About = ({ edit, setOpenEditPopup }) => {
  const AboutTranslation = translation.onboarding.about;

  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();
  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

  const [about, setAbout] = useState("");
  const [safe, setSafe] = useState(true);
  const [loading, setSLoading] = useState();

  const HandleChange = (e) => {
    setAbout(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const aboutContent = {};

    languages.forEach((language) => {
      aboutContent[language] =
        language === lang ? about : collaborator?.about[language] || "";
    });

    patchCollaborator({
      id: collaborator._id,
      content: {
        about: aboutContent,
        ...(edit ? {} : { onboarding_step: 4 }),
      },
    })
      .unwrap()
      .then()
      .catch((error) => {
        console.log(error);
        toast.error(
          edit
            ? translation?.updateErrorMessage?.[lang]
            : translation?.saveErrorMessage?.[lang]
        );
      });
  };

  return (
    <>
      {collaborator.restriction?.show_about ||
      !collaborator.restriction?.restrict_about ? (
        // <section className={`pt-[7vh] mx-5 pb-[120px] ${lang === 'ar' ? 'font-readexRegular' : 'font-SteradianRegular'}`}>
        <section
          className={`pt-[7vh] mx-5 pb-[120px] ${
            lang === "ar" ? "font-readexRegular" : "font-SteradianRegular"
          }`}
        >
          <div
            className={`${
              lang === "ar" ? "font-lalezarRegular" : "font-SteradianMedium"
            } text-2xl`}
          >
            {translation?.sectionTitle?.about?.[lang]}
          </div>
          <div className="py-5 text-lg">
            {AboutTranslation.subtitle?.[lang]}
          </div>
          <div>
            {restriction?.restrict_about ? (
              <p className="w-full border border-inputBottom bg-popcard p-3 mt-5 rounded-md h-[364px] overflow-scroll">
                {restriction?.about?.[lang]}
              </p>
            ) : (
              <textarea
                name="about"
                id="about"
                className="w-full border border-inputBottom bg-popcard p-3 mt-5 rounded-md"
                placeholder={AboutTranslation.aboutInput?.[lang]}
                rows={5}
                defaultValue={collaborator.about?.[lang]}
                onChange={HandleChange}
              />
            )}
          </div>
          <div className="button-container">
            {!collaborator.restriction?.restrict_about || !edit ? (
              <button
                className={`${
                  safe || collaborator?.restriction?.restrict_about
                    ? "btn-fields btn-active"
                    : "btn-fields"
                } ${lang === "ar" ? "!font-readexRegular" : ""}`}
                id="btn-next"
                onClick={
                  edit
                    ? (e) => {
                        handleSubmit(e);
                        setOpenEditPopup(false);
                      }
                    : handleSubmit
                }
              >
                {edit ? (
                  <img
                    src="/assets/img/loaders/loading.gif"
                    style={{
                      display: loading ? undefined : "none",
                      padding: "2px",
                    }}
                    width="23px"
                    alt=""
                    className="ml-[45%]"
                  />
                ) : null}
                {loading
                  ? null
                  : edit
                  ? AboutTranslation.buttonSave[lang]
                  : AboutTranslation.buttonNext[lang]}
              </button>
            ) : null}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default About;
