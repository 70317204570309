import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../appSlices/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  prepareHeaders: (headers, { getState }) => {
    let token = getState().auth.token;
    if (!token) {
      token = localStorage.getItem("token");
    }
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // console.log("result", result?.error?.status);

  /*  if (result?.error?.data?.message === 'something went wrong'){
    localStorage.clear()
    window.location.href = '/login'
  } */

  const refreshToken = localStorage.getItem("refreshToken");

  if (result?.error?.status === 471) {
    const refreshResult = await baseQuery(
      {
        url: process.env.REACT_REFRESH_TOKEN,
        method: "POST",
        body: { refreshToken: refreshToken },
      },
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      localStorage.setItem("token", refreshResult.data.data.accessToken);
      args = {
        ...args,
        headers: {
          ...args.headers,
          authorization: `Bearer ${refreshResult.data.data.accessToken}`,
        },
      };
      result = await baseQuery(args, api, extraOptions);
    } else if (refreshResult?.error) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["collaborator"],
  endpoints: (builder) => ({}),
});
