import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useLoggedStatus = () => {
  const selectCurrentToken = useSelector((state) => state.auth.token)
  const [logged, setLogged] = useState(false);
  const tokenLocal = localStorage.getItem('token')
  const collaboratorlocalId = localStorage.getItem('collaborator_id')
  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo.collaborator_id)


  useEffect(() => {
    if (selectCurrentToken && (collaborator === collaboratorlocalId)) {
      setLogged(true);
    } else if (tokenLocal && (collaborator === collaboratorlocalId)) {
      setLogged(true);
    }
  }, [selectCurrentToken, collaborator, collaboratorlocalId]);

  return logged;
};

export default useLoggedStatus;