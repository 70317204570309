import React, { useEffect, useState, useContext } from "react";
// import Close from "./Close";
import CopyToClipboard from "./CopyToClipboard";
import "../../../styles/Setting.scss";
// import { useGlobalState } from "..";
import SettingCode from "./SettingCode";
import SettingPass from "./SettingPass";
import SettingSlug from "./SettingSlug";
import SettingQrCode from "./SettingQrCode";
import { translation } from "../../../translation";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../redux/appSlices/auth/authSlice";

import { MdQrCodeScanner } from "react-icons/md";
import { IoLanguage } from "react-icons/io5";


import PwaInstallationPopup from "./PwaInstallationPopup";
import { useNavigate } from "react-router-dom";
import { useDownloadQrCodeQuery } from "../../../redux/appSlices/collaborator/collaboratorApiSlice";
import { toast } from "react-toastify";
import LanguageSelect from "../LanguageSelect";
import LanguageSelector from "../../UI/LanguageSelector";
import DefaultLanguageSelector from "./DefaultLanguageSelector";

const Setting = ({ edit, setPop, setOpenEditPopup }) => {
  const dispatch = useDispatch();
  // const [collaborator, setcollaborator] = useGlobalState("collaborator");

  // const [branding] = useGlobalState("branding");
  const branding = null;
  // const [lang] = useGlobalState("lang_tran");
  // const [trans] = useGlobalState("onboarding_trans");

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const collaborator_id = localStorage.getItem("collaborator_id");
  const [slug, setSlug] = useState(collaborator_id);
  const [getQrCode, setGetQrCode] = useState(false)
  const [qrCodeCount, setQrCodeCount] = useState(0);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  // set step 3
  const [step3, setStep3] = useState(false);
  const [url, setUrl] = useState(
    `${branding?.slug_url ? branding?.slug_url : "https://getpopcard.com/"}${collaborator?.slug ? collaborator?.slug : collaborator_id
    }`
  );

  const { data } = useDownloadQrCodeQuery(collaborator._id, {
    skip: !getQrCode,
  });

  // useEffect hooks
  useEffect(() => {
    if (qrCodeCount > 1) {
      toast(`✅ ${translation?.qrCode?.secondeClick?.[lang]}`)
    } else if (data) {
      toast(`✅ ${data.message?.[lang]}`)
    }
  }, [data, qrCodeCount])

  useEffect(() => {
    if (collaborator) {
      setSlug(collaborator.slug ? collaborator.slug : collaborator_id);
    }
  }, [collaborator]);

  useEffect(() => {
    setUrl(
      `${branding?.slug_url ? branding?.slug_url : "https://getpopcard.com/"
      }${slug}`
    );
  }, [slug]);

  const handleShare = () => {
    const encodedText = encodeURIComponent("");
    const encodedUrl = encodeURIComponent(url);
    window.location.href = `https://wa.me/?text=${encodedText}%20${encodedUrl}`;
  };

  const handleShareOther = async () => {
    try {
      await navigator.share({ url });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const handleStep1 = () => {
    setStep1(true);
  };

  const handleStep2 = () => {
    setStep2(true);
  };

  const handleStep3 = () => {
    setStep3(true);
  };

  console.log('lang ====> ', lang)

  return (
    <div className="max-w-[560px] m-auto">
      {/* <Close setStep1={setStep1} setStep2={setStep2} setPop={setPop} /> */}
      <section
        className={`mx-5 pt-[7vh] fields-content
        ${step1 ? "hidden" : "block"}
        ${step2 ? "hidden" : "block"}
        ${step3 ? "hidden" : "block"}
      `}
      >
        <div className={`text-2xl ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
          {translation?.sectionTitle?.settings?.[lang]}
        </div>
        <div className="flex flex-col gap-4 mt-[3rem] ">
          <div
            className="flex justify-between cursor-pointer"
            onClick={handleStep2}
          >
            <div className="flex gap-5 cursor-pointer">
              <img
                src="/assets/img/icons/key-icon.svg"
                alt=""
                className="setting-icon"
              />
              <div className="text-[18px]">
                {translation.settings["password-change-section"].title[lang]}
              </div>
            </div>
            <img
              src="/assets/img/icons/right-black.svg"
              alt=""
              // className="setting-icon"
              className={lang === 'ar' ? 'mr-auto rotate-180' : 'ml-auto'}
              // className={lang === 'ar' ? 'rotate-180' : ''}
              onClick={handleStep2}
            />
          </div>

          {/* add line */}

          <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />
          {/* start of Qr code section*/}
          <div
            className="flex justify-between cursor-pointer"
            // onClick={handleInstallClick}
            onClick={() => {
              setGetQrCode(true);
              setQrCodeCount(prevCount => prevCount + 1);
            }}

          >
            <div className="flex gap-5 cursor-pointer">
              <MdQrCodeScanner className="text-2xl" color="4E3131" />
              <div className="text-[18px]">
                {translation.settings["qrCode"][lang]}
              </div>
            </div>
            <img
              src="/assets/img/icons/right-black.svg"
              alt=""
              className={lang === 'ar' ? 'mr-auto rotate-180' : 'ml-auto'}
            />
          </div>
          {/*End of qr codee*/}
          <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />


          <div
            className="flex justify-between cursor-pointer"
            // onClick={handleInstallClick}
            onClick={() => {
              // setGetQrCode(true);
              // setQrCodeCount(prevCount => prevCount + 1);
            }}

          >
            <div className="flex gap-5 cursor-pointer">
              <IoLanguage className="text-2xl" color="4E3131" />
              <div className="text-[18px]">
                {translation.settings.defaultLang?.[lang]}

              </div>
            </div>
            <img
              src="/assets/img/icons/right-black.svg"
              alt=""
              className={lang === 'ar' ? 'mr-auto rotate-180' : 'ml-auto'}
            />
          </div>
          <div>
            {/* <LanguageSelect /> */}
            {/* <LanguageSelector /> */}
            <DefaultLanguageSelector />
          </div>


          <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />


          <div
            className="flex justify-between cursor-pointer"
            onClick={handleStep1}
          >
            <div className="flex gap-5">
              <img
                src="/assets/img/icons/more-icon-setting.svg"
                alt=""
                className="setting-icon"
              />
              <div className="text-[18px]">
                {translation.settings["custom-link-section"].title[lang]}
              </div>
            </div>
            <img
              src="/assets/img/icons/right-black.svg"
              alt=""
              className={lang === 'ar' ? 'mr-auto rotate-180' : 'ml-auto'}
            />
          </div>
          <div className="mt-[-10px] ml-[43px] overflow-hidden">
            <div className="text-[18px] text-[#4E3131]">
              {translation.settings["custom-link-section"].lienactuel[lang]}
            </div>
            <div
              // dir={lang === 'ar' ? 'rtl' : 'ltr'}
              dir='ltr'
              className="text-[14px] text-black">
              {branding?.vcard_url
                ? branding?.vcard_url + "/"
                : "app.getpopcard.com/"}

              <span className="text-[#795656]">{slug}</span>
            </div>
            <div className="text-[18px] text-[#4E3131] mt-3">
              {translation.settings["custom-link-section"].partager[lang]}
            </div>
            {/* coppy and share the link via whatssap and other */}
            <div className="flex gap-4 mt-[1rem]">
              <div className="flex flex-col items-center">
                <div className="bg-black p-2 px-3 rounded-full w-[44px] h-[44px]">
                  <CopyToClipboard
                    value={
                      branding?.slug_url
                        ? branding?.slug_url + slug
                        : "https://getpopcard.com/" + slug
                    }
                    color="white"
                  />
                </div>
                <div className="text-[11px] text-black">
                  {translation.settings["custom-link-section"].copier[lang]}
                </div>
              </div>
              <div className="flex flex-col items-center" onClick={handleShare}>
                <div className="bg-[#3EBA43] p-3 rounded-full">
                  <img
                    src="/assets/img/icons/whatsapp.svg"
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <div className="text-[11px] text-black">Whatsapp</div>
              </div>
              <div className="flex flex-col items-center">
                <div
                  className="bg-gray-300 p-3 rounded-full"
                  onClick={handleShareOther}
                >
                  <img
                    src="/assets/img/icons/3-dots.svg"
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <div className="text-[11px] text-black">
                  {translation.settings["custom-link-section"].other[lang]}
                </div>
              </div>
            </div>
          </div>
          <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />

          <SettingCode />

          {/* {user.profile_id !== "636e342219e2152d6f8ac87e" ? (
            <>
              <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  AppServices.post({
                    action: 3,
                  }).then((res) => {
                    window.open(
                      `https://testsuperadmin.getpopcard.com/auth/${res?.token}/${res?.collaborator_id}`
                    );
                  });
                }}
              >
                <div
                  className="flex gap-5 items-center cursor-pointer"
                  onClick={() => {
                    AppServices.post(
                      {
                        action: 3,
                      },
                      "/auth"
                    ).then((res) => {
                      window.open(
                        `https://testsuperadmin.getpopcard.com/auth/${res?.token}/${res?.collaborator_id}`
                      );
                    });
                  }}
                >
                  <IoStatsChart className="text-primaryP text-[18px]" />

                  <div className="text-[18px]">
                    {translation.profile.buttonRedirect[lang]}
                  </div>
                </div>
                <img
                  src="/assets/img/icons/right-black.svg"
                  alt=""
                  className="setting-icon"
                  onClick={() => {
                    AppServices.post(
                      {
                        action: 3,
                      },
                      "/auth"
                    ).then((res) => {
                      window.open(
                        `https://testsuperadmin.getpopcard.com/auth/${res?.token}/${res?.collaborator_id}`
                      );
                    });
                  }}
                />
              </div>
            </>
          ) : (
            ""
          )} */}

          <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />

          <div
            className="flex justify-between cursor-pointer"
            onClick={() => {
              const keys = Object.keys(localStorage);
              keys.forEach((key) => {
                if (key !== "user_token") {
                  localStorage.removeItem(key);
                }
              });
              localStorage.setItem("logout", "true");
              window.location.reload();
            }}
          >
            <div className="flex gap-5">
              <img
                src="/assets/img/icons/logout.svg"
                alt=""
                className="setting-icon"
              />
              <div className="text-[18px]">
                {translation?.loginPage?.buttonLogout?.[lang]}
              </div>
            </div>
            <img
              src="/assets/img/icons/right-black.svg"
              alt=""
              className={lang === 'ar' ? 'mr-auto rotate-180' : 'ml-auto'}
              onClick={() => {
                const keys = Object.keys(localStorage);
                keys.forEach((key) => {
                  if (key !== "user_token") {
                    localStorage.removeItem(key);
                  }
                });
                localStorage.setItem("logout", "true");
                window.location.reload();
                dispatch(logOut());
              }}
            />
          </div>
        </div>
      </section>
      {
        // step 2
        step1 && (
          <SettingSlug
            setStep1={setStep1}
            setStep2={setStep2}
            setSlug={setSlug}
            slug={slug}
          />
        )
      }
      {
        // step 3
        step2 && (
          <SettingPass
            setStep1={setStep1}
            setStep2={setStep2}
            setOpenEditPopup={setOpenEditPopup}
          />
        )
      }
      {/*Qr code*/}
      {
        // step 3
        step3 && (
          <SettingQrCode
            // setStep1={setStep1}
            // setStep2={setStep2}
            setStep3={setStep3}
          // setOpenEditPopup={setOpenEditPopup}
          />
        )
      }
    </div>
  );
};

export default Setting;
