import React, { useEffect, useState } from 'react'
import { useGetSocialsQuery } from '../../redux/appSlices/generalApiSlice'

const SingleSocila = ({ index, social, onDelete, handleChange, socials, setSocials, restrictSocial }) => {

  const [listSocials, setListSocials] = useState()
  const [openListSocial, setOpenListSocial] = useState(false)

  const { data } = useGetSocialsQuery()

  useEffect(() => {
    if (data) {
      setListSocials(data.data.socials)
    }
  }, [data])

  const handleSocialSelection = (selectedSocialId, id) => {
    const socialsIndex = socials.findIndex((item) => item._id === id);
    const selectedSocial = listSocials.find(
      (item) => item._id === selectedSocialId
    );
    const updatedSocials = [...socials];
    updatedSocials[socialsIndex] = {
      ...socials[socialsIndex],
      social_id: selectedSocial,
    };

    setSocials(updatedSocials)
  };

  return (
    <>
      <div className='mb-4'>
        <div className='flex justify-between items-end'>
          <div className='relative'>
            <div
              className='border-[0.7px] border-inputBottom bg-labelsBg h-11 flex items-center justify-between px-1 rounded-lg'
              onClick={() => { setOpenListSocial(!openListSocial) }}
            >
              <span>{social.social_id.label}</span>
              <img className={`w-[11px] h-[11px] ml-[10px] ${openListSocial ? 'rotate-180' : ''} `} src="../../assets/img/icons/arrow-down.svg" alt="show option" />
            </div>

            {openListSocial && (
              <div className='absolute h-[265px] w-[230px] bg-[#fff3e7] z-20 overflow-scroll p-[13px] border border-[#ccb7b7] rounded-[4px] mt-[10px]'>
                {listSocials.map((listSocial) => (
                  <>
                    <div
                      className='flex items-center gap-[21px] cursor-pointer'
                      onClick={() => {
                        setOpenListSocial(false);
                        handleSocialSelection(listSocial._id, social._id)
                      }}
                    >
                      <img className='w-[17px] h-[17px]' src={`../assets/img/socials/${listSocial.icon}`} />
                      <p className='text-[18px] tracking-[-.73px] leading-[25px]'>{listSocial.label}</p>
                    </div>
                    <div className='border-b-[.7px] border-[#ccb7b7] my-[8px]' />
                  </>
                ))}
              </div>
            )}
          </div>


          <div onClick={() => onDelete(social._id)}>
            <img src="../../assets/img/icons/delete.svg" alt="delete social" />
          </div>
        </div>
        <div className='mt-4'>
          {
            social.social_id.label === "Whatsapp" ?
              <div className='flex gap-5'>
                <div className='border-b border-inputBottom'>
                  <span>+</span>
                  <input
                    type='number'
                    className='w-16 bg-transparent text-lg focus:outline-none'
                    name='prefix'
                    defaultValue={social.url.split('-')[0] || ''}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <input
                  type='tel'
                  className='border-b border-inputBottom bg-transparent text-lg focus:outline-none'
                  name="phone"
                  placeholder='604'
                  defaultValue={social.url.split('-')[1] || ''}
                  onChange={(e) => handleChange(e, index)}
                />

              </div>
              :
              <input
                name="url"
                defaultValue={social.url}
                placeholder='www'
                className='block bg-transparent w-full text-lg border-b border-inputBottom focus:outline-none'
                onChange={(e) => handleChange(e, index)}
              />
          }
        </div>
      </div>
    </>

  )
}

export default SingleSocila