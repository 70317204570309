import React, { useEffect, useState } from 'react'
import useLoggedStatus from '../../hooks/useLoggedStatus';
import EditComponants from './EditComponants';
import { translation } from '../../translation';
import { useSelector } from 'react-redux';
import IdentificationForm from '../Modal/IdentificationForm';
import { usePostCreateIdentifMutation } from '../../redux/appSlices/collaborator/collaboratorApiSlice';
import ContactLock from './ContactLock';
import useTracking from '../../hooks/useTracking';
import useCollaboratorRestriction from '../../hooks/useCollaboratorRestriction';

const AddToContactButton = () => {

  const lang = useSelector((state) => state.collaborator.collaboratorInfo.language);
  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);

  const logged = useLoggedStatus();
  const restriction = useCollaboratorRestriction();
  const [postCreateIdentif] = usePostCreateIdentifMutation();
  const { sendTracking } = useTracking();

  const [fetchGetVcard, setFetchGetVcard] = useState(false)
  const [openIdentification, setOpenIdentification] = useState(false)
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const [dataRes, setDataRes] = useState("");
  const [openFormContactLock, setOpenFormContactLock] = useState(false)

  const [contactAccess, setContactAccess] = useState(false)

  useEffect(() => {
    setContactAccess(!collaborator.contact_locked)
  }, [collaborator.contact_locked])

  useEffect(() => {
    if (fetchGetVcard) {
      const timeoutId = setTimeout(() => {
        setFetchGetVcard(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [fetchGetVcard]);


  const handleDownload = () => {
    if (contactAccess) {
      const csvDataUri = `${process.env.REACT_APP_API_BASE_URL}/vcards/${collaborator._id}`

      sendTracking({
        action: 65,
        collaborator_id: collaborator.collaborator_id,
        componant: "MainButton",
      })
      setTimeout(() => {
        window.open(csvDataUri, "_self")
      }, 2000);

    } else {
      setOpenFormContactLock(true)
    }
  }

  const handleIdentify = () => {
    postCreateIdentif({
      card: "",
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      identified_in: collaborator?._id,
      tracking: localStorage.getItem("tracking"),
      identification_methode: 0,
      user_token: localStorage.getItem("user_token"),
    })
      .unwrap()
      .then((res) => {
        localStorage.setItem("completerId", res.data.identification._id);
        setDataRes(res.data.identification);
        setOpenIdentification(true);
      })
      .catch((err) => {
        console.log(err);
        setDataRes(err.data);
        localStorage.setItem("completerId", err?.data?._id);
        setOpenIdentification(true);
      })
  }

  return (
    <>
      <div className='mt-5'>
        {logged ?
          (<div className='flex justify-between items-center gap-[10px]'>
            <button className='h-[50px] w-[11.5rem] text-white bg-black rounded-md text-[13px]'
              style={collaborator?.branding?.color ? {backgroundColor: `${collaborator.branding.color}`} : {}}
              onClick={() => {
                setOpenEditPopup(true);
                setComponantToEditId(10);
              }}
            >
              <span className='inline-block max-w-[100px]'>{translation?.mainButton?.showSettings?.[lang]}</span>
            </button>
            <button className='h-[50px] w-[11.5rem] text-[#6c5352] border-[.8px] border-[#4e3131] rounded-md text-[13px]'
              onClick={() => {
                setOpenEditPopup(true);
                setComponantToEditId(9);
              }}
            >
              <span className='inline-block max-w-[110px]'>{translation?.mainButton?.updateMyContact?.[lang]}</span>
            </button>
          </div>)
          : collaborator.form ? 
          (<div className='flex justify-between items-center gap-[10px]'>
            <button className='h-[50px] w-[11.5rem] text-white bg-black rounded-md text-[13px] flex items-center justify-center gap-3'
            // style={collaborator?._id === '6516e5fea051e5b64acfd2d7' ? {backgroundColor: `#fff`} : {}}
            style={collaborator?.branding?.color ? {backgroundColor: `${collaborator.branding.color}`} : {}}
              onClick={() => { setFetchGetVcard(true); handleDownload() }}
            >
              <span className='inline-block max-w-[100px]'>
                {
                  !fetchGetVcard ?
                    translation?.mainButton?.addContact?.[lang]
                    : <img src="/assets/img/loaders/loading.gif" alt='loading Vcard' className='w-[23px] h-[23px]' />
                }
              </span>
            </button>
            <button
              className='h-[50px] w-[11.5rem] text-white bg-black rounded-md text-[13px]'
              // style={collaborator?._id === '6516e5fea051e5b64acfd2d7' ? {backgroundColor: `#fff`} : {}}
              style={collaborator?.branding?.color ? {backgroundColor: `${collaborator.branding.color}`} : {}}
              onClick={handleIdentify}
            >
              <span className='inline-block max-w-[100px]'>{translation?.mainButton?.identifyContact?.[lang]}</span>
            </button>
          </div>) : (
            <button className='h-[50px] w-full bg-black rounded-md text-white text-base flex items-center justify-center gap-3'
            // style={collaborator?._id === '6516e5fea051e5b64acfd2d7' ? {backgroundColor: `#fff`} : {}}
            style={collaborator?.branding?.color ? {backgroundColor: `${collaborator.branding.color}`} : {}}
              onClick={() => { setFetchGetVcard(true); handleDownload() }}
            >
              <span className='inline-block w-full'>
                {
                  !fetchGetVcard ?
                    translation?.mainButton?.addContact?.[lang]
                    : <img src="/assets/img/loaders/loading.gif" alt='loading Vcard' className='w-[23px] h-[23px]' />
                }
              </span>
            </button>
          )
        }
      </div>
      {(logged && openEditPopup) && (
        <EditComponants componantId={componantToEditId} setOpenEditPopup={setOpenEditPopup} />
      )}

      {openIdentification && collaborator.form && (
        <IdentificationForm close={setOpenIdentification} dataRes={dataRes} />
      )}

      {openFormContactLock && (
        <ContactLock
          id={collaborator._id}
          setOpenFormContactLock={setOpenFormContactLock}
          contactAccess={contactAccess}
          setContactAccess={setContactAccess}
          handleDownload={handleDownload}
        />
      )}
    </>

  )
}

export default AddToContactButton