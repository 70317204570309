import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useSuggestPlaces from "../../hooks/useSuggestPlaces";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import { translation } from "../../translation";
import { toast } from "react-toastify";

const ProfessionalInfo = ({ edit, setOpenEditPopup }) => {
  // const ProfessionalInfoTranslation = useSelector(
  //   (state) => state.translation.ProfessionalInfo
  // );

  const ProfessionalInfoTranslation = translation.onboarding.ProfessionalInfo
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();
  const suggestPlaces = useSuggestPlaces(document.getElementById("address"));

  // ############### DEV AREA
  // ######################
  // ########################################################

  const [safe, setSafe] = useState(false);
  const [loading, setLoading] = useState();
  const [professionalInfo, setProfessionalInfo] = useState({
    professional_email: restriction?.restrict_contact_email_ext
      ? collaborator?.professional_email?.includes("@")
        ? collaborator?.professional_email?.split("@")[0]
        : collaborator.professional_email
      : collaborator.professional_email ?? '',
    website: collaborator.website || "",
    address: collaborator.address || "",
  });
  const [patchCollaborator] = usePatchCollaboratorMutation();

  //change the adress to the selected one from google API
  useEffect(() => {
    if (suggestPlaces) {
      setProfessionalInfo({
        ...professionalInfo,
        address: suggestPlaces
      })
    }
  }, [suggestPlaces])

  useEffect(() => {
    if (
      professionalInfo.professional_email.length > 0 &&
      // (restriction.restrict_website
      //   ? true
      //   : professionalInfo.website.length > 0) &&
      (restriction.restrict_address
        ? true
        : professionalInfo.address.length > 0)
    ) {
      setSafe(true);
    } else {
      setSafe(false);
    }
  }, [professionalInfo]);

  const HandleChange = (e) => {
    setProfessionalInfo({
      ...professionalInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    patchCollaborator({
      id: collaborator._id,
      content: {
        ...professionalInfo,
        ...(edit ? {} : { onboarding_step: 3 }),
      },
    }).unwrap().then().catch(() => toast.error(edit ? translation?.updateErrorMessage?.[lang] : translation?.saveErrorMessage?.[lang]))
  };

  // ########################################################
  // ######################
  // ############### END DEV AREA

  return (
    <section className={`pt-[7vh] mx-5 pb-[120px] ${lang === 'ar' ? 'font-readexRegular' : 'font-SteradianRegular'}`}>
      <div className={`text-2xl ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
        {ProfessionalInfoTranslation.title[lang]}
      </div>
      <div className="mt-5 text-lg">
        {ProfessionalInfoTranslation.subtitle[lang]}
      </div>
      <div>
        <div className="">
          <div
            className={`mt-5 flex items-center gap-5 border-b border-inputBottom`}
          >
            <div className="">
              <img src="../../assets/img/icons/email.svg" alt="" srcSet="" />
            </div>
            <div className="w-full flex">
              <input
                dir='ltr'
                type="email"
                name="professional_email"
                className={`w-full bg-popcard p-3 focus:outline-none ${lang === 'ar' ? 'text-right' : ''}`}
                placeholder={ProfessionalInfoTranslation?.email[lang]}
                onChange={HandleChange}
                // defaultValue={
                //   restriction?.restrict_contact_email_ext
                //     ? collaborator?.professional_email?.split("@")[0]
                //     : collaborator.professional_email
                // }
                defaultValue={
                  restriction?.restrict_contact_email_ext
                    ? collaborator?.professional_email?.includes("@")
                      ? collaborator?.professional_email?.split("@")[0]
                      : collaborator.professional_email
                    : collaborator.professional_email
                }
              />
              {restriction.restrict_contact_email_ext ? (
                <span className="flex items-center justify-center border border-inputBottom rounded-r-[9px] px-[10px]">
                  @{restriction.contact_email_ext}
                </span>
              ) : null}
            </div>
          </div>

          {restriction.show_website ? (
            <div
              className={`mt-5 flex items-center gap-5 border-b border-inputBottom ${restriction.restrict_website && "pb-[10px]"
                }`}
            >
              <div className="icon-container">
                <img
                  src="../../assets/img/icons/website.svg"
                  alt=""
                  srcSet=""
                />
              </div>
              {restriction.restrict_website ? (
                <div className="flex w-full">
                  {restriction.website}
                  <img
                    src="../../assets/img/icons/lock.svg"
                    alt=""
                    srcset=""
                    className="ml-auto"
                  />
                </div>
              ) : (
                <div className="w-full">
                  <input
                    dir='ltr'
                    type="text"
                    name="website"
                    id="website"
                    className={`w-full bg-popcard p-3 focus:outline-none ${lang === 'ar' ? 'text-right' : ''}`}
                    placeholder={ProfessionalInfoTranslation.webSite[lang]}
                    onChange={HandleChange}
                    defaultValue={collaborator.website}
                  />
                </div>
              )}
            </div>
          ) : null}

          {restriction.show_address ? (
            <div
              className={`mt-5 flex items-center gap-5 border-b border-inputBottom ${restriction.show_address && "pb-[10px]"
                }`}
            >
              <div className="icon-container">
                <img
                  src="../../assets/img/icons/address.svg"
                  alt=""
                  srcSet=""
                />
              </div>
              {restriction.restrict_address ? (
                <div className="flex w-full">
                  {restriction.address}
                  <img
                    src="../../assets/img/icons/lock.svg"
                    alt=""
                    srcset=""
                    className="ml-auto"
                  />
                </div>
              ) : (
                <div className="input-container w-full">
                  <input
                    type="text"
                    className="w-full bg-popcard p-3 focus:outline-none"
                    name="address"
                    id="address"
                    rows={2}
                    required=""
                    placeholder={ProfessionalInfoTranslation.adresse[lang]}
                    autoComplete="off"
                    onChange={HandleChange}
                    defaultValue={collaborator.address}
                  // value={professionalInfo?.address}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="button-container">
        <button
          className={`${safe ? "btn-fields btn-active" : "btn-fields"} ${lang === 'ar' ? '!font-readexRegular' : ''}`}
          onClick={
            edit
              ? (e) => {
                handleSubmit(e);
                setOpenEditPopup(false);
              }
              : handleSubmit
          }
          id="btn-next"
          disabled={!safe}
        >
          {edit ? (
            <img
              src="/assets/img/loaders/loading.gif"
              style={{
                display: loading ? undefined : "none",
                padding: "2px",
              }}
              width="23px"
              alt=""
              className="ml-[45%]"
            />
          ) : null}
          {loading
            ? null
            : edit
              ? ProfessionalInfoTranslation.buttonSave[lang]
              : ProfessionalInfoTranslation.buttonNext[lang]}
        </button>
      </div>
    </section>
  );
};

export default ProfessionalInfo;
