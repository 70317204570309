import React from "react";
import LanguageSelector from "../../UI/LanguageSelector";

const StepOne = ({setAuthStep, authTranslation, lang}) => {
  return (
    <div className="flex flex-col items-center  pt-[8rem]">
      <div className="flex flex-col items-center mb-[6rem]">
        <img
          src={`${
            // branding?.logo
            // ? branding?.logo :
            "../../assets/img/logo-black.svg"
          }`}
          style={{ margin: "0 10px" }}
          alt=""
          srcSet=""
          className="w-[139px] object-contain"
        />

        <div className={`text-[20px] mt-[5rem] text-center ${lang === 'ar' ? 'font-readexRegular' : 'font-SteradianRegular'}`}>{authTranslation.title[lang]}</div>
      </div>

      <LanguageSelector />
      <div className="button-container">
        <button
          className={`btn-welcome ${lang === 'ar' ? '!font-readexRegular' : ''}`}
          onClick={() => {
            setAuthStep(1)
          }}
        >
          {authTranslation.button[lang]}
        </button>
      </div>
    </div>
  );
};

export default StepOne;
