import React from "react";

function Loading() {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="loader-wrapper-1">
          <svg
            width="21vh"
            height="100%"
            viewBox="0 0 81 21"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Popcard-UX/UI"
              stroke="none"
              stroke-width="1"
              fill="black"
              fill-rule="evenodd"
            >
              <g
                id="Main-page"
                transform="translate(-20.000000, -65.000000)"
                fill="black"
                fill-rule="nonzero"
              >
                <g id="Group-15" transform="translate(20.000000, 65.000000)">
                  <path
                    d="M18.0451138,8.98958527 C18.5177937,9.37782324 18.7786885,9.89715455 18.7786885,10.4467382 C18.7786885,10.9963218 18.5177937,11.5131321 18.0451138,11.9038911 C17.9254091,12.0022111 17.7688723,12.0526316 17.6123354,12.0526316 C17.4557985,12.0526316 17.2992617,12.0022111 17.179557,11.9038911 C16.9401477,11.707251 16.9401477,11.3870808 17.179557,11.1904408 C17.4220357,10.9912797 17.5540177,10.7290931 17.5540177,10.4467382 C17.5540177,10.1643833 17.4220357,9.9021966 17.179557,9.70303556 C16.9401477,9.50639555 16.9401477,9.18622528 17.179557,8.98958527 C17.4189663,8.79294526 17.8057045,8.79294526 18.0451138,8.98958527 Z M19.850018,13.4324426 C19.7273107,13.5292848 19.5668472,13.5789474 19.4063838,13.5789474 C19.2459203,13.5789474 19.0854569,13.5292848 18.9627495,13.4324426 C18.7173349,13.2387584 18.7173349,12.9234008 18.9627495,12.7297166 C20.4824328,11.5303644 20.4824328,9.57614035 18.9627495,8.37430499 C18.7173349,8.18062078 18.7173349,7.86526316 18.9627495,7.67157895 C19.2081642,7.47789474 19.6077497,7.47789474 19.8531644,7.67157895 C21.8636769,9.26078273 21.8636769,11.8432389 19.850018,13.4324426 Z M21.781673,6.14842351 C24.7394423,8.54817314 24.7394423,12.4552655 21.781673,14.8550151 C21.6615136,14.9500052 21.5043821,15 21.3441696,15 C21.1839571,15 21.0299066,14.9500052 20.9097473,14.8525154 C20.6694285,14.6575357 20.6694285,14.3400689 20.9097473,14.1450892 C23.3868791,12.1352989 23.3868791,8.86314027 20.9097473,6.85334996 C20.6694285,6.6583703 20.6694285,6.34090343 20.9097473,6.14592377 C21.150066,5.95094411 21.5413543,5.95094411 21.781673,6.14842351 Z"
                    id="Shape"
                    class="svg-elem-1"
                  ></path>
                  <path
                    d="M2.93283582,15.9786629 L2.93283582,10.8577525 L5.97761194,10.8577525 C7.76865672,10.8577525 9.2238806,10.4096728 10.3208955,9.49217639 C11.4402985,8.57467994 12,7.35846373 12,5.84352774 C12,4.371266 11.4402985,3.19772404 10.3208955,2.32290185 C9.17910448,1.44807966 7.74626866,1 5.97761194,1 L0,1 L0,16 L2.93283582,15.9786629 Z M5.82089552,3.47510669 C6.76119403,3.47510669 7.52238806,3.68847795 8.10447761,4.11522048 C8.68656716,4.54196302 8.97761194,5.13940256 8.97761194,5.88620199 C8.97761194,6.67567568 8.68656716,7.29445235 8.10447761,7.74253201 C7.5,8.19061166 6.73880597,8.42532006 5.82089552,8.42532006 L2.93283582,8.42532006 L2.93283582,3.47510669 L5.82089552,3.47510669 Z"
                    id="Shape"
                    class="svg-elem-2"
                  ></path>
                  <path
                    d="M17.962,5 L17.9615651,7.62164774 C17.0340645,7.63013485 16.2773507,7.94676397 15.6703297,8.59322505 C15.0549451,9.24859314 14.7472527,10.0621535 14.7472527,11.0339062 C14.7472527,12.0056589 15.0549451,12.8192193 15.6703297,13.4745874 C16.2857143,14.1299555 17.0549451,14.4463401 18,14.4463401 L17.962,14.445 L17.9619045,16.9999988 C16.3349366,16.9965861 14.9787364,16.4761751 13.8933039,15.4574212 L13.6923077,15.2599005 C12.5714286,14.1073566 12,12.661027 12,10.9435106 L12.001,10.979 L12.0066911,10.6966883 C12.0691159,9.24329653 12.5679891,7.99753218 13.4885871,6.95939523 L13.6923077,6.74011528 C14.8131868,5.58757139 16.2417582,5 17.978022,5 L17.962,5 Z"
                    id="Combined-Shape"
                    class="svg-elem-3"
                  ></path>
                  <g id="Group-14" transform="translate(25.000000, 0.000000)">
                    <path
                      d="M0,21 L2.72514559,21 L2.72514559,14.8014706 C3.18297005,15.3970588 3.72799917,15.8382353 4.38203411,16.1470588 C5.03606905,16.4558824 5.73370632,16.6102941 6.49674708,16.6102941 C8.08823211,16.6102941 9.39630199,16.0588235 10.4209567,14.9338235 C11.4456115,13.8088235 11.9470383,12.3970588 11.9470383,10.6985294 C11.9470383,9 11.4456115,7.61029412 10.4427579,6.50735294 C9.43990432,5.40441176 8.1536356,4.85294118 6.60575291,4.85294118 C5.79910981,4.85294118 5.03606905,5.02941176 4.33843178,5.38235294 C3.64079451,5.73529412 3.05216306,6.24264706 2.5943386,6.88235294 L2.5943386,5.13970588 L0,5.13970588 L0,21 Z M9.09108568,10.7205882 C9.09108568,11.7132353 8.78586938,12.5073529 8.17543677,13.1470588 C7.56500415,13.7867647 6.78016222,14.1176471 5.84271214,14.1176471 C4.90526206,14.1176471 4.12042013,13.8088235 3.50998752,13.1691176 C2.89955491,12.5294118 2.5943386,11.7352941 2.5943386,10.7647059 C2.5943386,9.81617647 2.89955491,9.02205882 3.50998752,8.38235294 C4.12042013,7.74264706 4.88346089,7.43382353 5.82091098,7.43382353 C6.75836106,7.43382353 7.52140182,7.76470588 8.13183444,8.40441176 C8.74226705,9.04411765 9.04748335,9.83823529 9.04748335,10.7867647 L9.09108568,10.7205882 Z"
                      id="Shape"
                      class="svg-elem-4"
                    ></path>
                    <path
                      d="M18.7472036,4.85294118 C17.0467128,4.85294118 15.6296371,5.42647059 14.4741753,6.55147059 C13.3405148,7.67647059 12.7736845,9.06617647 12.7736845,10.7205882 C12.7736845,12.4191176 13.3405148,13.8088235 14.4741753,14.9338235 C15.6296371,16.0588235 17.0467128,16.6323529 18.7472036,16.6323529 C20.2078817,16.6323529 21.4723492,16.1911765 22.4970039,15.2867647 C23.565261,14.3823529 24.1974948,13.2132353 24.4373076,11.7794118 L21.7339632,11.7794118 C21.5377527,12.4632353 21.1671329,13.0147059 20.6221038,13.4338235 C20.0770747,13.8529412 19.4448409,14.0735294 18.7254025,14.0735294 C17.7879524,14.0735294 17.0249116,13.7647059 16.414479,13.125 C15.8040464,12.4852941 15.4988301,11.6911765 15.4988301,10.7426471 C15.4988301,9.79411765 15.8040464,9 16.414479,8.36029412 C17.0249116,7.72058824 17.7879524,7.41176471 18.7254025,7.41176471 C19.4448409,7.41176471 20.0552735,7.61029412 20.5785015,8.00735294 C21.1235306,8.42647059 21.4723492,8.95588235 21.6685597,9.61764706 L24.3937053,9.61764706 C24.1538925,8.22794118 23.4998575,7.08088235 22.4534016,6.19852941 C21.4069457,5.31617647 20.1424782,4.875 18.6818001,4.875 L18.7472036,4.85294118 Z"
                      id="Path"
                      class="svg-elem-5"
                    ></path>
                    <path
                      d="M32.3929347,16.3235294 L34.9872733,16.3235294 L34.9872733,9.19852941 C34.9872733,7.85294118 34.5730512,6.79411765 33.7664081,6.02205882 C32.959765,5.25 31.8261044,4.85294118 30.3654264,4.85294118 C29.0355553,4.85294118 27.9454971,5.22794118 27.0516493,5.97794118 C26.1578016,6.72794118 25.678176,7.67647059 25.6127725,8.80147059 L28.2289122,8.80147059 C28.3161169,8.29411765 28.5559297,7.89705882 28.9265495,7.61029412 C29.2971693,7.32352941 29.7767949,7.16911765 30.3436252,7.16911765 C30.9322567,7.16911765 31.4118823,7.32352941 31.7825021,7.63235294 C32.1531219,7.94117647 32.3275312,8.36029412 32.3275312,8.86764706 L32.3275312,9 L29.1445611,9.75 C27.8364913,10.0588235 26.87724,10.5 26.2886086,11.0955882 C25.678176,11.6911765 25.3729597,12.4632353 25.3729597,13.4117647 C25.3729597,14.3602941 25.7217783,15.1323529 26.3976144,15.7279412 C27.0734505,16.3235294 27.9454971,16.6323529 29.0137542,16.6323529 C29.6895903,16.6323529 30.321824,16.4779412 30.9322567,16.1911765 C31.5208881,15.9044118 32.0005137,15.4852941 32.3711335,14.9338235 L32.3929347,16.3235294 Z M29.6241868,14.4044118 C29.1009588,14.4044118 28.7085379,14.2941176 28.4251227,14.0735294 C28.1417076,13.8529412 28.0109006,13.5441176 28.0109006,13.125 C28.0109006,12.75 28.1635087,12.4411765 28.4469239,12.1985294 C28.730339,11.9558824 29.1663623,11.7573529 29.7985961,11.6029412 L32.2839289,11.0294118 L32.2839289,11.6029412 C32.2839289,12.375 32.0223149,13.0147059 31.4990869,13.5661765 C30.975859,14.1176471 30.3436252,14.4044118 29.6023856,14.4044118 L29.6241868,14.4044118 Z"
                      id="Shape"
                      class="svg-elem-6"
                    ></path>
                    <path
                      d="M43.0519062,7.52205882 C43.2045144,7.52205882 43.291719,7.52205882 43.4443272,7.54411765 L43.7931458,7.61029412 L43.7931458,4.98529412 C43.7495435,4.96323529 43.6623388,4.96323529 43.5315318,4.96323529 L43.2045144,4.96323529 C42.4850759,4.96323529 41.8528421,5.16176471 41.2642107,5.53676471 C40.6973804,5.91176471 40.2177548,6.46323529 39.847135,7.16911765 L39.847135,5.18382353 L37.2527964,5.18382353 L37.2527964,16.3676471 L39.977942,16.3676471 L39.977942,10.9411765 C39.977942,9.90441176 40.2395559,9.08823529 40.7845851,8.49264706 C41.3296142,7.875 42.0708538,7.56617647 43.030105,7.56617647 L43.0519062,7.52205882 Z"
                      id="Path"
                      class="svg-elem-7"
                    ></path>
                    <path
                      d="M49.4160483,16.6102941 C50.2444925,16.6102941 51.0075333,16.4338235 51.7051706,16.0808824 C52.3810067,15.7279412 52.9696381,15.2205882 53.4274626,14.5588235 L53.4274626,16.3455882 L56,16.3455882 L56,0 L53.2748544,0 L53.2748544,6.68382353 C52.81703,6.11029412 52.2720008,5.66911765 51.5961647,5.33823529 C50.9421298,5.02941176 50.2226914,4.875 49.4596506,4.875 C47.8899667,4.875 46.603698,5.42647059 45.5790433,6.55147059 C44.5543885,7.67647059 44.0529617,9.08823529 44.0529617,10.7867647 C44.0529617,12.4852941 44.5543885,13.875 45.5572421,14.9779412 C46.5600957,16.0808824 47.8463644,16.6323529 49.3942471,16.6323529 L49.4160483,16.6102941 Z M46.865312,10.7647059 C46.865312,9.81617647 47.1705283,9 47.7809609,8.36029412 C48.3913935,7.72058824 49.1762354,7.41176471 50.1136855,7.41176471 C51.0511356,7.41176471 51.8141764,7.74264706 52.424609,8.38235294 C53.0350416,9.02205882 53.3402579,9.81617647 53.3402579,10.7647059 C53.3402579,11.7132353 53.0350416,12.5073529 52.424609,13.1470588 C51.8141764,13.7867647 51.0511356,14.1176471 50.1136855,14.1176471 C49.1762354,14.1176471 48.3913935,13.8088235 47.7809609,13.1691176 C47.1705283,12.5294118 46.865312,11.7352941 46.865312,10.7867647 L46.865312,10.7647059 Z"
                      id="Shape"
                      class="svg-elem-8"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
    </div>
  );
}

export default Loading;
