import { apiSlice } from "../api/apiSlice";

export const generaleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //get Socials table
    getSocials: builder.query({
      query: () => `/socials`,
    }),

    //get Stores table
    getStores: builder.query({
      query: () => `/stores`,
    }),

    //Upload a picture
    postUploadPic: builder.mutation({
      query: (imageFile) => {
        const bodyFormData = new FormData();
        bodyFormData.append("file", imageFile);
        return {
          url: `/upload`,
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
    }),

    //Delete a picture
    deletePic: builder.mutation({
      query: (imageName) => ({
        url: `/collaborators/images`,
        method: 'DELETE',
        body: {'image': imageName}
      })
    })
  }),
});

export const {
  useGetSocialsQuery,
  useGetStoresQuery,
  usePostUploadPicMutation,
  useDeletePicMutation
} = generaleApiSlice;
