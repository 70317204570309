import React, { useEffect, useState } from "react";
// import { useGlobalState } from "..";
import { FaCameraRetro } from "react-icons/fa";
// import AppServices from "../services/AppServices";
import { useSelector } from "react-redux";
import { translation } from "../../../translation";
import { useUploadImageMutation } from "../../../redux/appSlices/collaborator/collaboratorApiSlice";

const TakingPic = (props) => {
  const {
    onUpload,
    setPop,
    setBgImage,
    setImagePrev,
    setBgPosX,
    setBgPosY,
    handleImageOrientation,
    setZoom,
  } = props;
  const [stream, setStream] = useState(null);
  const [open, setOpen] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [error, setError] = useState(null);
  // const [lang] = useGlobalState("lang_tran");
  // const [trans] = useGlobalState("onboarding_trans");
  const [uploadPercent, setUploadPercent] = useState(0);
  // const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  // const [user, setUser] = useGlobalState("user");
  const [uploadPercentStr, setUploadPercentStr] = useState("0%");

  const [uploadImage] = useUploadImageMutation()

  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );


  useEffect(() => {
    if (!open) {
      setStream(null);
    }
  }, [open]);

  function openCamera() {
    if (open) return;
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((s) => {
        setStream(s);
        setOpen(true);
        setShowIcon(true);
      })
      .catch((err) => setError(err));
  }

  function closeCamera() {
    if (!stream) return;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setStream(null);
    setOpen(false);
  }

  function handleTakePicture() {
    if (!stream) return;
    const video = document.querySelector("video");
    const canvas = document.createElement("canvas");

    video.addEventListener("playing", () => {
      // Set the canvas dimensions to the actual video stream dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");

      // Add a delay to ensure the video frame is ready
      setTimeout(() => {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (!blob) {
            // If the blob is still null, use the toDataURL() method as a fallback
            const dataURL = canvas.toDataURL("image/jpeg");
            const base64Data = dataURL.replace(/^data:image\/jpeg;base64,/, "");
            blob = new Blob([base64Data], { type: "image/jpeg" });
          }

          const file = new File([blob], "picture.jpg", {
            type: "image/jpeg",
          });

          upload(file);
        });

        setOpen(false);
        setShowIcon(false);
        closeCamera();
      }, 200);
    });

    // Make sure the video stream is playing before capturing
    video.play();
  }

  function handleUploadProgress(e) {
    let percentage = (e.progress * 100).toFixed(2);
    const roundCircum = 2 * 70 * Math.PI;
    const svgPercentage = (percentage * roundCircum) / 100;
    setUploadPercent(svgPercentage);
    setUploadPercentStr(Math.floor(percentage - 1) + "%");
  }

  function upload(file) {
    if (uploadPercent > 0) return;
    if (!file) return;

    uploadImage(file).unwrap().then((res) => {
      setBgImage({ url: res.data.imagePath });
      setImagePrev(res.data.imagePath);
      setBgPosX(0);
      setBgPosY(0);
      onUpload(true);
      setPop(false);
      setZoom(100);
    }).catch((err) => {
      console.log(err);
    });

    // AppServices.upload(
    //   file,
    //   "file",
    //   "/upload",
    //   {
    //     "Content-Type": file.type,
    //   },
    //   handleUploadProgress
    // )
    //   .then((res) => {
    //     setUploadPercent(0);
    //     setUploadPercentStr("0%");
    //     setBgImage({ url: res.url });
    //     setImagePrev(res.url);
    //     setBgPosX(0);
    //     setBgPosY(0);
    //     onUpload(true);
    //     setPop(false);
    //     setZoom(100);

    //     setOnboardingUser({
    //       ...onboardingUser,
    //       cover: {
    //         url: res.url,
    //       },
    //     });

    //     setUser({
    //       ...user,
    //       cover: {
    //         url: res.url,
    //       },
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  return (
    <>
      <label
        htmlFor="profile-picturer"
        className="text-[17px] font-[400] text-black hover:bg-gray-200 p-3 rounded-xl cursor-pointer"
        onClick={openCamera}
      >
        {translation?.uploaderPic?.upload?.[lang]}
      </label>

      <div className="Box">
        {showIcon ? (
          <FaCameraRetro
            className="camera-icon z-[999] fixed cursor-pointer h-[40px] w-[40px] left-[50%] top-[95%] text-[#fff]"
            onClick={handleTakePicture}
          />
        ) : (
          ""
        )}

        <input
          type="file"
          className="hidden"
          onChange={(e) => {
            const file = e.target.files[0];
            upload(file);
          }}
        />
        <div className="camera">
          {open ? (
            <video
              autoPlay
              playsInline
              muted
              ref={(ref) => {
                if (ref) {
                  ref.srcObject = stream;
                }
              }}
              className="z-[998] bg-black fixed h-full w-full left-0 top-0 object-contain"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TakingPic;
