import React, { useEffect, useRef, useState } from "react";
import { translation } from "../../translation";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import "./auth.css";

import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../redux/appSlices/auth/authSlice";
import { setOnboardingStep } from "../../redux/appSlices/collaborator/collaboratorSlice";
import { useLoginMutation } from "../../redux/appSlices/auth/authApiSlice";
import EditComponants from "../../componants/profile/EditComponants";
import LanguageSelect from "../../componants/profile/LanguageSelect";

const Login = ({ edit, name, picture, setComponantIdRender }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailRef = useRef();

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  let lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  if (!lang) {
    lang = "fr";
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(false);
  const [safe, setSafe] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  // const isPwa = !!window.matchMedia("(display-mode: standalone)").matches;
  const isPwa = false;
  const [login] = useLoginMutation();

  useEffect(() => {
    emailRef.current.focus();
    const tokenLocal = localStorage.getItem("token");
    const collaboratorlocalId = localStorage.getItem("collaborator_id");
    if (tokenLocal && collaboratorlocalId) {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        return navigate(`/code`);
      }
      navigate(`/${collaboratorlocalId}`);
    }
  }, []);

  useEffect(() => {
    if (email !== "" && password !== "" && password.length >= 6) {
      setSafe(true);
    }
  }, [email, password]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const localUserToken = localStorage.getItem("user_token");
    try {
      const userData = await login({
        email: email.toLowerCase(),
        password,
        //add the user_token if there was a previous one in the local storage
        ...(localUserToken ? { userToken: localUserToken } : {}),
      }).unwrap();
      dispatch(setCredentials(userData));
      dispatch(
        setOnboardingStep({
          collaborator_id: userData.data.collaborator_id,
          onboarding_step: userData.data.onboarding_step,
        })
      );
      // set collaborator for offline qrcode
      localStorage.setItem(
        "collaborator",
        JSON.stringify(userData.data.collaborator)
      );
      setEmail("");
      setPassword("");
      if (window.matchMedia("(display-mode: standalone)").matches) {
        navigate(`/code`);
      } else {
        navigate(`/${userData.data.collaborator_id}`);
      }
    } catch (err) {
      console.log(err);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };

  return (
    <div className="wrapper">
      {!edit && !isPwa && (
        <div
          className="popup-header cursor-pointer"
          onClick={() => {
            window.location = "https://getpopcard.com/";
          }}
        >
          <img src="../../assets/img/icons/close.svg" alt="" />
        </div>
      )}

      <section className="login-content">
        {isPwa && !edit && (
          <div className="absolute top-[30px] right-[20px]">
            <LanguageSelect />
          </div>
        )}
        <div
          className={
            isPwa ? "w-[180px] m-auto mb-[70px] pt-[80px]" : "avatar-container"
          }
        >
          <img
            src={
              isPwa
                ? "/assets/img/logo-black.svg"
                : collaborator?.profile_picture
                  ? `${process.env.REACT_APP_IMAGE_PATH}${collaborator.profile_picture}`
                  : "/assets/img/logo-black.svg"
            }
            alt="Popcard"
            className={
              isPwa
                ? "w-full"
                : !collaborator.profile_picture
                  ? "w-[100%] p-[10px] !object-contain"
                  : "!object-cover"
            }
          />
        </div>

        <div className={`avatar-title ${lang === 'ar' ? '!font-readexRegular' : ''}`}>
          {collaborator?.first_name
              ? `${translation?.loginPage?.greeting?.[lang]} ${collaborator?.first_name} !`
              : translation?.loginPage?.loginTitle?.[lang]}
        </div>
        {error ? (
          <div class="avatar-description text-dander ">
            🚫 {translation?.loginPage.loginError?.[lang]}
          </div>
        ) : (
          <div className={`avatar-description ${lang === 'ar' ? '!font-readexRegular' : ''}`}>
            {translation?.loginPage?.loginDescription?.[lang]}
          </div>
        )}

        <form>
          <div className="inputs-container">
            <div className="single-input">
              <label htmlFor="email" className={`label ${lang === 'ar' ? '!font-readexRegular' : ''}`}>
                {translation.loginPage.email[lang]}
              </label>
              <input
                dir='ltr'
                ref={emailRef}
                defaultValue={email}
                className="login-input text-[18px]"
                onChange={handleEmailChange}
                type="email"
                name="email"
                id="email"
              />
            </div>
            <div className="single-input button_father_password mt-[18px]">
              <label htmlFor="password" className={`label ${lang === 'ar' ? '!font-readexRegular' : ''}`}>
                {translation.loginPage.pass[lang]}
              </label>
              <input
                dir='ltr'
                defaultValue={password}
                className="login-input relative"
                type={passwordVisible ? "text" : "password"}
                onChange={handlePasswordChange}
                name="password"
                id="password"
              />
              <div
                // id="password-togglee"
                // className={`absolute top-[68%] cursor-pointer translate-y-[-50%] ${lang == 'ar' ? 'left-[0.7rem]' : 'right-[0.7rem]'}`}
                className='absolute top-[68%] cursor-pointer translate-y-[-50%] right-[0.7rem]'
                onClick={togglePasswordVisibility}>
                {passwordVisible ? (
                  <RiEyeOffLine className="text-[#795656]" />
                ) : (
                  <RiEyeLine className="text-[#795656]" />
                )}
              </div>
            </div>
          </div>
          {!isPwa && (
            <div className="forgot-password-container cursor-pointer">
              <div
                className={`forgot-password-link ${lang === 'ar' ? '!font-readexRegular' : ''}`}
                onClick={() => {
                  if (!edit) {
                    setOpenLoginPopup(true);
                  } else {
                    setComponantIdRender(13);
                  }
                }}
              >
                {translation?.loginPage?.passOublie?.[lang]}
              </div>
            </div>
          )}
          <div className="login-btn-wrapper">
            <button
              className={`${safe ? "pop-btn-disabled btn-active" : "pop-btn-disabled"} ${lang === 'ar' ? '!font-readexRegular' : ''}`}
              onClick={handleSubmit}
              id="btn-login"
              disabled={!safe}
            >
              {translation?.loginPage?.loginTitle?.[lang]}
            </button>
          </div>
        </form>
      </section>
      {openLoginPopup && (
        <EditComponants componantId={13} setOpenEditPopup={setOpenLoginPopup} />
      )}
    </div>
  );
};

export default Login;
