import React from 'react'

const RestrictSingleSocial = ({ index, social, handleRestrictChange }) => {
  return (
    <div className='mb-4'>
      <div className="border-[0.7px] border-inputBottom bg-labelsBg h-11 w-[122px] flex items-center justify-between px-[9px] rounded-lg">
        <span>{social?.social_id?.label}</span>
        <img
          className="w-[15px] h-[15px]"
          src="../../assets/img/icons/lock.svg"
          alt="show option"
        />
      </div>
      <div className='my-[15px]'>
        {social.social_id.label === "Whatsapp" ?
          <div className='flex gap-5'>
            <div className='border-b border-inputBottom'>
              <span>+</span>
              <input
                type='number'
                className='w-16 bg-transparent text-lg focus:outline-none'
                name='prefix'
                defaultValue={social.url.split('-')[0] || ''}
                // onChange={(e) => handleRestrictChange(e, index)}
                onChange={(e) => handleRestrictChange(e, index)}
              />
            </div>
            <input
              type='tel'
              className='border-b border-inputBottom bg-transparent text-lg focus:outline-none'
              name="phone"
              placeholder='604'
              defaultValue={social.url.split('-')[1] || ''}
              // onChange={(e) => handleRestrictChange(e, index)}
              onChange={(e) => handleRestrictChange(e, index)}
            />

          </div>
          :
          <input
            name="url"
            defaultValue={social.url}
            placeholder='www'
            className='block bg-transparent w-full text-lg border-b border-inputBottom focus:outline-none'
            // onChange={(e) => handleRestrictChange(e, index)}
            onChange={(e) => handleRestrictChange(e, index)}
          />
        }
      </div>
    </div>
  )
}

export default RestrictSingleSocial