import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useLoggedStatus from '../../hooks/useLoggedStatus';
import useOutsideClosePopup from '../../hooks/useOutsideClosePopup';
import { translation } from '../../translation';
import { usePatchCollaboratorMutation, useUploadProfileImageMutation } from '../../redux/appSlices/collaborator/collaboratorApiSlice';
import EditComponants from './EditComponants';

function MainCard({ infinite_animation }) {

  const logged = useLoggedStatus();

  const collaborator = useSelector((state) => state.collaborator.collaboratorInfo);
  const lang = useSelector((state) => state.collaborator.collaboratorInfo.language);
  const restriction = collaborator?.backoffice_id;

  const [openLoginPopup, setOpenLoginPopup] = useState(false)
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [imageSrc, setImageSrc] = useState(null)
  const [imageSrcTest, setImageSrcTest] = useState(null)
  const cropperRef = useState(null);
  const [imageElement, setImageElement] = useState();
  const [swipped, setSwipped] = useState(false);
  const [clickCounter, setClickCounter] = useState(0);
  const [profileLoading, setProfileLoading] = useState(false)

  const wrapperRef = useRef(null);
  useOutsideClosePopup(wrapperRef, setOpenPopup);

  const [patchCollaborator] = usePatchCollaboratorMutation();
  const [uploadProfileImage] = useUploadProfileImageMutation();

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      setImageSrcTest(file)
      setOpenEditPopup(true)
      setProfileLoading(true)
    }
  };

  const [startX, setX] = useState();
  const [startY, setY] = useState();
  const [startMouseX, setMouseX] = useState();
  const [mouseDown, setMouseDown] = useState(false);

  const handleSwipeOrClick = () => {
    setClickCounter((prevCounter) => prevCounter + 1);
  };

  const detectSwipeX = (endX) => {
    if (endX < startX) {
      setSwipped(true);
      handleSwipeOrClick();
    }
    setTimeout(() => {
      setSwipped(false);
    }, 300);
  };

  const swiperCard = () => {
    setSwipped(true);
    setTimeout(() => {
      setSwipped(false);
    }, 300);
  };

  useEffect(() => {
    if (infinite_animation) {
      setTimeout(() => {
        swiperCard();
      }, 400);
      setInterval(() => {
        swiperCard();
      }, 5000);
    }
  }, []);

  const detectSwipeMouseX = (endX) => {
    if (endX < startMouseX) {
      setSwipped(true)
      handleSwipeOrClick();
    }
    setTimeout(() => {
      setSwipped(false)
    }, 300);
  };

  const className = ['translate-x-[-300px]', 'translate-x-[0px]'];

  return (
    <>
      {/* <section className={`z-10 transform !translate-x-[${swipped ? '-300px' : '0px'}] transition-transform ease-in duration-[.3s]`}> */}
      <section className={`z-10 transform ${swipped ? 'translate-x-[-300px]' : 'translate-x-[0px]'} transition-transform ease-in duration-[.3s]`}>
        <div
          className={`h-[162px] flex items-center bg-white rounded-[10px] mt-[-115px] shadow-lg`}
          onTouchStart={(e) => {
            setX(e.targetTouches[0].pageX);
          }}
          onTouchEnd={(e) => {
            detectSwipeX(e.changedTouches[0].pageX);
          }}
          onMouseDown={(e) => {
            setMouseX(e.clientX);
            setMouseDown(true);
          }}
          onMouseUp={(e) => {
            if (mouseDown) {
              detectSwipeMouseX(e.clientX);
            }
            setMouseDown(false);
          }}
        >
          <div className='relative'>
            {collaborator.profile_picture ?
              <div className='h-[162px] w-[134px]'>
                <img className={`object-cover h-[162px] w-[134px] ${lang === 'ar' ? 'rounded-r-xl' : 'rounded-l-xl' }`} src={`${process.env.REACT_APP_IMAGE_PATH}${collaborator.profile_picture}`} />
              </div> :
              <div className={`flex items-center justify-center h-[162px] w-[134px] bg-labelsBg border-2 border-dashed border-inputBottom ${lang === 'ar' ? 'rounded-r-xl' : 'rounded-l-xl' }`}>
                <img className={`object-cover ${lang === 'ar' ? 'rounded-r-xl' : 'rounded-l-xl' }`} src='/assets/img/icons/default-profile.svg' alt="profile picture" />
              </div>}

            {logged &&
              <div onClick={() => setOpenPopup(true)} className={`cursor-pointer absolute top-0 ${lang === 'ar' ? 'right-0 rounded-tr-[9px] rounded-bl-[14.5px]' : 'left-0 rounded-tl-[9px] rounded-br-[14.5px]'} bg-black p-2 z-20 h-[29px] w-[29px]`}>
                <img className='' src="/assets/img/icons/edit-icon-image.svg" alt="change profile picture" />
              </div>}

            {profileLoading && 
            <div className={`absolute flex justify-center items-center h-[162px] w-[134px] bg-white z-30 top-0 left-0 border-2 border-dashed border-inputBottom ${lang === 'ar' ? 'rounded-r-xl' : 'rounded-l-xl' }`}>
              <img src="/assets/img/loaders/loading.gif" alt='loading Vcard' className='w-[50px] h-[50px]' />
              </div>}
          </div>
          <div className={collaborator.collaborator_id === '8Im3szwNSd9WxlGqkO' ? `h-[151px] ml-[28px] mr-2 flex items-center` : `h-[151px] ml-[28px] mr-2`}>
            {
              (swipped !== null && !logged && clickCounter % 2 !== 0) ? (
                <>
                  <h1 className='font-SteradianMedium font-bold  text-[22px] tracking-[-.97px]'>{collaborator?.first_name},</h1>
                  <p className='mt-[10px] tracking-[-.65px] break-words'>{translation?.mainCard?.title?.[lang]}</p>
                  <div
                    className={`flex justify-end text-[15px] items-center mt-[20px] cursor-pointer ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium  tracking-[1.41px]'}`}
                    onClick={() => { setOpenLoginPopup(true); }}
                  >
                    {translation?.loginPage?.loginTitle?.[lang]} {lang === 'ar' ? '←' : '→'}
                  </div>
                </>
              ) : (
                <div className='max-h-[151px] overflow-y-auto'>
                  <h1 className={`font-SteradianMedium text-[22px] tracking-[-.97px]`}>{collaborator?.first_name} {' '} {collaborator?.last_name}</h1>
                  <div className='mt-[10px] text-[16px] tracking-[-.65px]'>
                    <p className={collaborator.collaborator_id === '8Im3szwNSd9WxlGqkO' ? 'text-center' : collaborator.title?.[lang]?.length > 50 ? 'text-[14px]' : ''}>{collaborator.title?.[lang]}</p>
                    <p>{restriction?.restrict_company_name ? restriction.company_name?.[lang] : collaborator.company?.[lang]}</p>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </section>

      {(logged && openPopup) &&
        <div className='fixed inset-0 bg-[#00000060] z-[99999999] flex items-center justify-center'>
          <div ref={wrapperRef} className=' flex-row  bg-white p-5 rounded-xl w-96'>
            <label htmlFor='profilePicture' className='cursor-pointer flex w-full text-[17px] rounded-xl hover:bg-gray-200 font-[400] p-3'>
              {translation.changeProfilePicture?.title1[lang]}
            </label>
            <input
              type="file"
              accept="image/*"
              className="files hidden"
              name="profile-image"
              onChange={handleFileUpload}
              id="profilePicture"
            />
            <div onClick={() => {
              setOpenPopup(false);
              setImageSrc(null);
              patchCollaborator(
                {
                  id: collaborator._id,
                  content: { profile_picture: '' }
                });
            }}
              className='cursor-pointer text-[17px] rounded-xl hover:bg-gray-200 font-[400] p-3'>
              {translation.changeProfilePicture?.deleteProfilePic[lang]}
            </div>

            {logged && openEditPopup && (
              <EditComponants
                componantId={11}
                setProfileLoading={setProfileLoading}
                setOpenEditPopup={setOpenEditPopup}
                imageSrc={imageSrcTest}
                setOpenPopup={setOpenPopup}
              />
            )}
          </div>
        </div>
      }
      {openLoginPopup && (
        <EditComponants
          componantId={12}
          setOpenEditPopup={setOpenLoginPopup}
        />
      )}
    </>
  )
}

export default MainCard